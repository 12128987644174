import forOwn from "lodash/forOwn";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { PatientBase } from "../../../../../backend/src/graph/patients/patient-base";
import { PractitionerEntry } from "./practitioner";
import { canBookRecall } from "@shared/patient-routine-appointment";

dayjs.extend(advancedFormat);
dayjs.extend(isSameOrAfter);

export class PatientEntry extends PatientBase {
  public dentist: PractitionerEntry;
  public hygienist: PractitionerEntry;
  public bg_class = "bg-gray-200";
  public text_class = "text-gray-700";
  constructor(baseItem?: PatientBase) {
    super();
    if (!baseItem) return;
    forOwn(baseItem, (value, key) => {
      if (key === "dentist" || key === "hygienist") {
        this[key] = new PractitionerEntry(baseItem[key]);
      } else {
        this[key] = value;
      }
    });
  }

  // #region Routine Exam
  public get routineExamDateText(): string {
    if (!this.next_exam_date) return "";
    const date = dayjs(this.next_exam_date).format("Do MMMM YYYY");
    return `You already have a dental checkup booked on ${date}.`;
  }

  public get routineExamDueDateText(): string {
    if (!canBookRecall(this, "dentist")) return "";
    return this._routineDateToText(this.dentist_recall_date, false);
  }

  public get hasRoutineExamInfo(): boolean {
    return !!(this.dentist_recall_date || this.dentist_recall_interval);
  }

  public get isRoutineExamDue(): boolean {
    if (!this.dentist_recall_date) return false;
    return dayjs(this.dentist_recall_date).isSameOrAfter(dayjs());
  }
  // #endregion

  // #region Routine Hygiene
  public get routineHygieneDateText(): string {
    if (!this.next_scale_and_polish_date) return "";
    const date = dayjs(this.next_scale_and_polish_date).format("Do MMMM YYYY");
    return `You already have a hygiene appointment booked on ${date}.`;
  }

  public get routineHygieneDueDateText(): string {
    if (!canBookRecall(this, "hygienist")) return "";
    return this._routineDateToText(this.hygienist_recall_date, true);
  }

  public get hasRoutineHygieneInfo(): boolean {
    return !!(this.hygienist_recall_date || this.hygienist_recall_interval);
  }

  public get isRoutineExamOverdue(): boolean {
    if (!this.dentist_recall_date) return false;
    return dayjs(this.dentist_recall_date).isBefore(dayjs());
  }

  public get isRoutineHygieneOverdue(): boolean {
    if (!this.hygienist_recall_date) return false;
    return dayjs(this.hygienist_recall_date).isBefore(dayjs());
  }

  public get isRoutineHygieneDue(): boolean {
    if (!this.hygienist_recall_date) return false;
    return dayjs(this.hygienist_recall_date).isSameOrAfter(dayjs());
  }
  // #endregion

  private _routineDateToText(date: string | null, is_hygiene: boolean): string {
    if (!date) return "";

    const type = is_hygiene ? "hygiene appointment" : "dental checkup";
    const practitionerType = is_hygiene ? "hygienist" : "dentist";
    const interval = is_hygiene ? this.hygienist_recall_interval : this.dentist_recall_interval;
    const practitionerName = is_hygiene ? this.hygienist?.full_name : this.dentist?.full_name;

    const practitionerText = practitionerName ? `(${practitionerName}) ` : "";
    const recommendedText = !interval
      ? ""
      : ` Your ${practitionerType} ${practitionerText}recommends a ${type} every ${interval} month${interval === 1 ? "" : "s"}.`;

    const isOverdue = dayjs(date).isBefore(dayjs());
    if (isOverdue) return `You are due for your ${type}.${recommendedText}`;
    const dateText = dayjs(date).format("MMMM YYYY");
    return `Your ${type} is due in ${dateText}.${recommendedText}`;
  }
}
