import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const HSO_REGION_TO_LOCALE_MAP = {
  APAC: "AU",
  UK: "GB",
};

@Pipe({ name: "practiceTime", standalone: true })
export class PracticeTimePipe implements PipeTransform {
  transform(data: { value: string | Date; format: string; time_zone: string; hso_region?: string }): string {
    const isValid = dayjs(data.value).isValid();
    let meridian = "";

    if (!isValid) return "Invalid Date";

    try {
      let { format } = data;

      if (/(h|H|hh|HH|m|mm|s|ss|SSS)/.test(format) && data.hso_region) {
        format = format.replace(/(h|H|hh|HH|m|mm|s|ss|SSS) [aA]/, "$1"); // Remove AM/PM from format and let the pipe handle it

        const region = HSO_REGION_TO_LOCALE_MAP[data.hso_region] ?? data.hso_region;
        const date = new Date(data.value).toLocaleTimeString(`en-${region}`, { timeZone: data.time_zone }); // Get the time in the correct locale
        meridian = date.split(" ")[1] ? ` ${date.split(" ")[1]}` : ""; // Get the AM/PM from the locale

        if (meridian) {
          format = format.replace("HH", "h"); // If the time is in 12-hour format, change the format to match
        }
      }

      return `${dayjs.utc(data.value).tz(data.time_zone).format(format)}${meridian}`;
    } catch (e) {
      return "Invalid Date";
    }
  }
}
