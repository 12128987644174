import { Component } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";
import { PortalInPracticeService } from "../../services/portal-in-practice.service";
import { SkeletonDefaultComponent } from "../skeletons/skeleton-default/skeleton-default.component";
import { SkeletonLoaderComponent } from "../skeletons/skeleton-loader/skeleton-loader.component";
import { ClockComponent } from "../clock/clock.component";

@Component({
  selector: "dentr-concierge-diagnostics",
  templateUrl: "./concierge-diagnostics.component.html",
  standalone: true,
  imports: [ModalComponent, SkeletonDefaultComponent, SkeletonLoaderComponent, ClockComponent],
})
export class ConciergeDiagnosticsComponent {
  constructor(private _portalInPracticeSevice: PortalInPracticeService) {}

  get diagnosticsData() {
    const { diagnosticsData } = this._portalInPracticeSevice;
    if (!diagnosticsData) return [];

    return Object.keys(diagnosticsData).map((key) => ({
      key: this._formatKey(key),
      value: diagnosticsData[key],
    }));
  }

  private _formatKey(key: string): string {
    if (key.toLowerCase() === "id") {
      return "Device ID";
    }

    return key
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/, (char) => char.toUpperCase()) // Capitalize the first word
      .replace(/\b(?:id)\b/gi, "ID") // Replace 'id' with 'ID'
      .replace(/\b(?:jwt)\b/gi, "JWT"); // Replace 'jwt' with 'JWT'
  }
}
