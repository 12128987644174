import { SHARED } from "src/app/shared/shared";
import { Component } from "@angular/core";

@Component({
  selector: "dentr-login-terms-ca",
  templateUrl: "./login-terms-ca.component.html",
  standalone: true,
  imports: [SHARED],
})
export class LoginTermsCAComponent {}
