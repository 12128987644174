<div class="align-items-start mb-4 flex flex-col rounded-lg bg-gray-100 p-4 shadow" data-e2e-appointment-card>
  <div *ngFor="let appointment of appointments; let index = index" class="w-full">
    <div *ngIf="index == 0" class="mb-5 mt-0 flex items-center justify-between">
      <p class="heading-lg m-0" data-e2e-appointment-card-date>
        {{
          { value: appointment.start_time, format: "dddd Do MMM YYYY", time_zone: commonService.time_zone, hso_region: commonService.hso_region } | practiceTime
        }}
      </p>
      <span *ngIf="!showCancelButton && appointments.length === 1">
        <ng-container *ngTemplateOutlet="price; context: { appointment: this.appointment }"></ng-container>
      </span>
      <button *ngIf="showCancelButton && appointments.length == 1 && canCancel" class="link btn-text btn-sm -mr-3" (click)="cancelAppointment(appointment)">
        Cancel
      </button>
    </div>
    <div *ngIf="appointments.length > 1" class="mb-4 mt-4 flex items-center justify-between">
      <p class="text-primary mb-0 text-sm font-semibold uppercase tracking-wider" data-e2e-appointment-card-num>appointment {{ index + 1 }}</p>
      <span *ngIf="!showCancelButton">
        <ng-container *ngTemplateOutlet="price; context: { appointment: this.appointment }"></ng-container>
      </span>
      <button *ngIf="showCancelButton && canCancel" class="link btn-text btn-sm -mr-3" (click)="cancelAppointment(appointment)">Cancel</button>
    </div>

    <ng-container *ngTemplateOutlet="start_time; context: { appointment: this.appointment, isMultiple: appointments.length > 1 }"></ng-container>

    <ng-container *ngTemplateOutlet="practitioner; context: { appointment: this.appointment, isMultiple: appointments.length > 1 }"></ng-container>

    <ng-container *ngTemplateOutlet="location; context: { appointment: this.appointment, isMultiple: appointments.length > 1 }"></ng-container>
  </div>
</div>

<ng-template #practitioner let-appointment="appointment" let-isMultiple="isMultiple">
  <div class="mb-4 flex items-center" [ngClass]="{ 'ml-6': isMultiple }" *ngIf="appointment.practitioners?.length > 1">
    <div class="flex flex-col items-center pr-3">
      <img
        *ngIf="appointment.practitioner?.image_url"
        class="mx-auto h-9 w-9 shrink rounded-full shadow-sm"
        src="{{ appointment.practitioner?.image_url }}"
        alt="{{ appointment.practitioner.first_name }} {{ appointment.practitioner.last_name }} display picture"
      />
      <span
        *ngIf="!appointment.practitioner?.image_url"
        class="inline-flex h-9 w-9 items-center justify-center rounded-full border-2 text-sm shadow-sm"
        [ngClass]="appointment.practitioner.bg_class"
      >
        <span class="font-semibold uppercase" [ngClass]="appointment.practitioner.text_class">
          {{ appointment.practitioner.first_name.charAt(0) }}{{ appointment.practitioner.last_name.charAt(0) }}
        </span>
      </span>
    </div>
    <div>
      <label class="sr-only" for="practitioner_select">Select Practitioner</label>
      <select
        id="practitioner_select"
        name="practitioner_select"
        class="w-full text-ellipsis pr-8"
        (change)="changePractitioner($event, appointment)"
        [ngModel]="appointment.practitioner.id"
      >
        <option *ngFor="let practitioner of appointment.practitioners" [value]="practitioner.id">
          <span>{{ practitioner.first_name }} {{ practitioner.last_name }} ({{ practitioner.role }})</span>
        </option>
      </select>
    </div>
  </div>
  <div
    *ngIf="!appointment.practitioners || appointment.practitioners?.length === 1"
    class="mb-4 flex items-center"
    [ngClass]="{ 'ml-6': isMultiple }"
    data-e2e-appointment-card-practitioner
  >
    <div class="flex items-center pr-3">
      <img
        *ngIf="appointment.practitioner?.image_url"
        class="mx-auto h-9 w-9 shrink rounded-full shadow-sm"
        src="{{ appointment.practitioner?.image_url }}"
        alt="{{ appointment.practitioner.first_name }} {{ appointment.practitioner.last_name }} display picture"
      />
      <span
        *ngIf="!appointment.practitioner?.image_url"
        class="inline-flex h-9 w-9 items-center justify-center rounded-full border-2 text-sm shadow-sm"
        [ngClass]="appointment.practitioner.bg_class"
      >
        <span class="font-semibold uppercase" [ngClass]="appointment.practitioner.text_class">
          {{ appointment.practitioner.first_name.charAt(0) }}{{ appointment.practitioner.last_name.charAt(0) }}
        </span>
      </span>
    </div>
    <div>
      <p class="heading-md m-0">{{ appointment.practitioner.first_name + " " + appointment.practitioner.last_name }}</p>
      <p class="m-0 text-sm font-medium text-gray-500">{{ appointment.practitioner.role }}</p>
    </div>
  </div>
</ng-template>

<ng-template #start_time let-appointment="appointment" let-isMultiple="isMultiple">
  <div class="mb-4 flex items-center" [ngClass]="{ 'ml-6': isMultiple }" data-e2e-appointment-card-time>
    <span class="pr-3 text-[2.25rem]">
      <ng-icon class="text-primary" name="heroClock"></ng-icon>
    </span>
    <div>
      <p class="heading-md m-0">
        {{ { value: appointment.startTime, format: "HH:mm", time_zone: commonService.time_zone, hso_region: commonService.hso_region } | practiceTime }}
      </p>
      <p class="m-0 text-sm font-medium text-gray-500">
        {{ getAppointmentReason(appointment.reason) }} ({{ appointment.practitioner.duration ? appointment.practitioner.duration : appointment.duration }} mins)
      </p>
    </div>
  </div>
</ng-template>

<ng-template #location let-appointment="appointment" let-index="index" let-isMultiple="isMultiple">
  <div class="flex items-center" [ngClass]="{ 'mt-6': isMultiple }" data-e2e-appointment-card-location>
    <span class="pr-3 text-[2.25rem]">
      <ng-icon class="text-primary" name="heroMapPin"></ng-icon>
    </span>
    <div>
      <p class="heading-md m-0">{{ appointment.site_name }}</p>
      <p class="m-0 text-sm font-medium text-gray-500">
        {{ appointment.addressString }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #price let-appointment="appointment">
  <div *ngIf="showPricing">
    <span *ngIf="appointment.price">
      {{ { currency: commonService.practice.currency_code, value: appointment.price / 100 } | practiceCurrency }}
    </span>
    <span *ngIf="appointment.price === 0">FREE</span>
  </div>
</ng-template>
