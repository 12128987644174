// eslint-disable-next-line dentr/moment-use
import moment from "moment";
import { PatientBase } from "../backend/src/graph/patients/patient-base";

export function hasApptWithinRecallWindow(recall_interval: number | null | undefined, recall_date: string | null, next_appt_date: string | null): boolean {
  if (!recall_interval || !recall_date || !next_appt_date) return false;

  const startOfToday = moment().startOf("day");
  const startOfWindow = moment.max(startOfToday, moment(recall_date).startOf("day"));
  const endOfWindow = moment(startOfWindow).add(6, "weeks").endOf("day"); // Allow a bit of leeway for the recall window

  // Check if the next appointment date is between today and the end of the window
  return moment(next_appt_date).endOf("day").isBetween(startOfToday, endOfWindow, null, "[]");
}

export function canBookRecall(patient: PatientBase, type: "dentist" | "hygienist"): boolean {
  const recallDate = patient[`${type}_recall_date`];
  const recallInterval = patient[`${type}_recall_interval`];
  const nextApptDate = type === "hygienist" ? patient.next_scale_and_polish_date : patient.next_exam_date;

  if (!recallDate) return false;

  // You cant book a recall scale and polish if youve never had an exam and scale and polish before
  if (type === "hygienist" && !patient.last_scale_and_polish_date && !patient.last_exam_date) return false;

  // You cant book a recall routine exam if youve never had an exam before
  if (type === "dentist" && !patient.last_exam_date) return false;

  // You cant book a recall if you already have an appointment within the recall window
  if (hasApptWithinRecallWindow(recallInterval, recallDate, nextApptDate)) return false;

  return true;
}
