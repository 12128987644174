import { Injectable } from "@angular/core";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { NavigationService } from "./navigation.service";
import { JWTService } from "./jwt.service";
import Bugsnag from "@bugsnag/js";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { CommonService } from "./common.service";
import { Subject, Subscription, debounceTime, filter } from "rxjs";
import { LambdaRequest } from "@apis/_core/types/LambdaRequest";
import { PipMetricsService } from "./analytics/pip-metrics.service";
import { CacheService } from "./cache.service";
import { Constants } from "src/constants";
import { LocationService } from "./location.service";
import { E_PipServiceMode } from "@shared/constants";
import { PortalInPracticeService } from "./portal-in-practice.service";
import { SessionService } from "./session.service";
import { ActivityMonitorService } from "./activity-monitor.service";
import { OverlayService } from "./overlay.service";
import { PipLoginInactivityModalComponent } from "src/app/pip-login/pip-login-inactivity-modal/pip-login-inactivity-modal.component";

dayjs.extend(advancedFormat);

@Injectable({
  providedIn: "root",
})
export class PipLoginService {
  public onLogout = new Subject<void>();

  constructor(
    private _navigationService: NavigationService,
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _commonService: CommonService,
    private _pipMetricsService: PipMetricsService,
    private _cacheService: CacheService,
    private _locationService: LocationService,
    private _pipService: PortalInPracticeService,
    private _sessionService: SessionService,
    private _activityMonitorService: ActivityMonitorService,
    private _overlayService: OverlayService
  ) {}
  private _day: number | null = null;
  private _month: number | null = null;
  private _year: string | null = null;
  private _surnameInitial: string | null = null;
  private _firstName: string | null = null;
  private _init: boolean;
  private _inactivityMonitorSubscription: Subscription;
  private _jwtTokenChangedSubscription: Subscription;
  private _isLoggingOut = false;

  public get init(): boolean {
    return this._init;
  }

  public set init(init: boolean) {
    this._init = init;
  }

  public set day(day: number | null) {
    this._day = day;
  }
  public get day(): number | null {
    return this._day;
  }

  public set month(month: number | null) {
    this._month = month;
  }

  public get month(): number | null {
    return this._month;
  }

  public set year(year: string | null) {
    this._year = year;
  }

  public get year(): string | null {
    return this._year;
  }

  public set surnameInitial(surnameInitial: string | null) {
    this._surnameInitial = surnameInitial;
  }

  public get surnameInitial(): string | null {
    return this._surnameInitial;
  }

  public get firstName(): string | null {
    return this._firstName;
  }

  public set firstName(firstName: string | null) {
    this._firstName = firstName;
  }

  public get dateOfBirthFormatted(): string {
    return dayjs(this.dateOfBirth).format("Do MMMM YYYY");
  }

  public get dateOfBirth(): string {
    if (!this._year || !this._month || !this._day) {
      Bugsnag.notify(new Error("Date of birth missing one of year, month or day"));
      throw new Error("Date of birth missing one of year, month or day");
    }

    return `${this._year}-${this._month.toString().padStart(2, "0")}-${this._day.toString().padStart(2, "0")}`;
  }

  public startCheckIn(): void {
    if (this._pipService.serviceMode === E_PipServiceMode.CONCIERGE) {
      this.navigate("enter-dob");
      return;
    }

    this.navigate("enter-day");
  }

  public handleDobEntry(dob: string): void {
    const year = dob.split("/")[2];
    const day = dob.split("/")[0];
    const month = dob.split("/")[1];

    this.year = year;
    this.day = +day;
    this.month = +month;

    this._pipMetricsService.trackCheckInDobEntered();

    // Small delay to allow the user to see the last digit they typed before we navigate for concierge mode
    const timeout = this._pipService.serviceMode === E_PipServiceMode.KIOSK ? 750 : 0;

    setTimeout(() => {
      this.navigate("enter-surname");
    }, timeout);
  }

  public reset(): void {
    this._day = null;
    this._month = null;
    this._year = null;
    this._surnameInitial = null;
    this._firstName = null;
  }

  public search() {
    const { surnameInitial, dateOfBirth } = this;
    const { urlSiteId } = this._commonService;

    if (!surnameInitial || !dateOfBirth || !urlSiteId) {
      const message = `Missing surname initial (${surnameInitial}), date of birth (${dateOfBirth}) or site id (${urlSiteId})`;
      Bugsnag.notify(message);
      this.navigate("error");
      return;
    }

    const query = `
{
    query: pip_authorize(
      date_of_birth: "${dateOfBirth}"
    patient_identifier: "${surnameInitial}"
    site_id: "${urlSiteId}"
    ${this.firstName ? `first_name: "${this._firstName}"` : ""}
  ) {
    success
    token
    locked
  }
}
`;

    this._httpService.query(GRAPHQL_OPERATION_NAMES.PIP_AUTHORIZE, query).subscribe((response) => {
      if (response.errors) {
        console.error("error in pip_authorize graph call", response.errors);
        Bugsnag.notify(new Error(`Pip login error: ${response.errors}`));
        this.navigate("error");
        return;
      }

      const { success, token, locked } = response.data.query;
      if (this._jwtService.hasExpired) {
        Bugsnag.leaveBreadcrumb("JWT token has expired");
      }

      if (success && token) {
        try {
          Bugsnag.leaveBreadcrumb("success response from pip_authorize", { data: response.data });
          this.loginPatientInPractice(token);
          Bugsnag.leaveBreadcrumb("logged in patient in practice");
          this._pipMetricsService.trackCheckedIn(); // Track after setting the token so we can get the patient id on the metric
          Bugsnag.leaveBreadcrumb("Patient in practice tracked checked in");
          this.startInactivityMonitor();
          Bugsnag.leaveBreadcrumb("Patient in practice started activity monitor");
          this._locationService.href = `/my-dental${this.pipShortcut}`;
          Bugsnag.notify("Patient in practice logged in");
          return;
        } catch (error) {
          Bugsnag.leaveBreadcrumb("handle success response error", error);
          Bugsnag.notify("Failed to handle pip-authorizer token");

          throw error;
        }
      }

      if (locked) return;

      this._pipMetricsService.trackCheckInPatientNotFound();

      if (this.firstName) {
        this.navigate("no-match");
        return;
      }

      if (!locked) this.navigate("enter-first-name");
    });
  }

  public navigate(route?: string) {
    const path = this._pipService.deviceLocked ? "locked" : route;
    this._navigationService.navigate(`/pip-login/${path || ""}`);
  }

  /**
   * Start the inactivity monitor and logout the user if they are inactive for 60 seconds
   */
  public startInactivityMonitor(): void {
    if (this._inactivityMonitorSubscription) this._inactivityMonitorSubscription.unsubscribe();
    if (this._jwtTokenChangedSubscription) this._jwtTokenChangedSubscription.unsubscribe();

    Bugsnag.leaveBreadcrumb("starting inactivity monitor for patient in practice");

    this._inactivityMonitorSubscription = this._activityMonitorService.activity
      .pipe(
        debounceTime(60000),
        filter(() => this._jwtService.isPatientUnauthenticated() && this._jwtService.isPip())
      )
      .subscribe(() => {
        this._inactivityMonitorSubscription.unsubscribe();
        this._overlayService.open({
          component: PipLoginInactivityModalComponent,
        });
      });

    this._jwtTokenChangedSubscription = this._jwtService.onJWTChanged.subscribe((change) => {
      if (change.jwt.access_level === LambdaRequest.enumAccessLevel.PUBLIC_IN_PRACTICE) {
        this._inactivityMonitorSubscription.unsubscribe();
      }
    });
  }

  public get pipShortcut(): string {
    return this._cacheService.get(Constants.PIP_LOGIN_SHORTCUT_STORAGE_KEY) || "";
  }

  public get hasPipShortcut(): boolean {
    return !!this.pipShortcut;
  }

  /**
   * Renames the current (portal in practice) jwt and replaces it with the patient in practice jwt
   */
  public loginPatientInPractice(token: string) {
    const publicInPracticeJwt = this._cacheService.get("jwt");
    this._cacheService.set("public_in_practice_jwt", publicInPracticeJwt);
    this._jwtService.setToken(token);
  }

  public async logoutPatientInPractice(): Promise<void> {
    if (this._isLoggingOut || !this._jwtService.isPatientInPractice()) return;

    this._isLoggingOut = true;
    this._navigationService.navigate("/pip-login/logout");
    this.onLogout.next();
    await this._sessionService.clear(); // Clearing the PATIENT_IN_PRACTICE session
    this._cacheService.delete(Constants.PIP_LOGIN_SHORTCUT_STORAGE_KEY);
    this._cacheService.deleteSession(Constants.PATIENT_ACTIONS_SESSION_STORAGE_KEY);
    this._jwtService.restorePublicInPracticeToken();
    this._isLoggingOut = false;
    window.location.href = "/pip-login";
  }
}
