<span *ngIf="is_modal; then thenBlock; else elseBlock"></span>
<ng-template #thenBlock>
  <dentr-modal>
    <div #modalScroll>
      <div class="pr-4">
        <ng-container *ngTemplateOutlet="legal"></ng-container>
        <button class="btn-secondary w-full sm:w-auto" (click)="closeModal()">Close</button>
      </div>
    </div>
  </dentr-modal>
</ng-template>
<ng-template #elseBlock>
  <ng-container *ngTemplateOutlet="legal"></ng-container>
</ng-template>

<ng-template #legal>
  <ng-container [ngSwitch]="iso_country_code">
    <p *ngSwitchCase="'GB'" class="mb-6">
      <strong>PLEASE NOTE THESE TERMS AND CONDITIONS APPLY TO YOUR USE OF THIS PORTAL</strong>
    </p>
    <p class="mb-6" *ngSwitchDefault><strong>PLEASE NOTE THESE TERMS AND CONDITIONS &amp; PRIVACY POLICY APPLY TO YOUR USE OF THIS PORTAL</strong></p>
  </ng-container>

  <ng-container [ngSwitch]="iso_country_code">
    <dentr-login-terms [is_modal]="false" *ngSwitchDefault></dentr-login-terms>
  </ng-container>

  <div id="privacy">
    <ng-container [ngSwitch]="iso_country_code">
      <dentr-privacy-policy [is_modal]="false" *ngSwitchDefault></dentr-privacy-policy>
    </ng-container>
  </div>
</ng-template>
