import { SHARED } from "src/app/shared/shared";
import { Component } from "@angular/core";

@Component({
  selector: "dentr-login-terms-default",
  templateUrl: "./login-terms-default.component.html",
  standalone: true,
  imports: [SHARED],
})
export class LoginTermsDefaultComponent {}
