<p class="mb-4">
  <strong class="block">Australia and New Zealand Privacy Policy</strong>
</p>
<p class="mb-4">
  This online portal (the “Portal”) provides a means of collecting important information requested by your dental care provider, which includes both dental
  practices and referral centres where applicable (“Dental Care Provider”), from you and passes that information to your Dental Care Provider. The Portal is a
  platform supplied to Australian based Dental Care Providers by Henry Schein One Australia ARBN 091 413 599 and to New Zealand based Dental Care Providers by
  Henry Schein One New Zealand (“we”, “our” or “us”) . We are committed to the protection of personal information and health information within the scope of
  applicable law. This Privacy Policy covers our treatment of personal information and health information (together referred to as personal information in this
  policy) that we collect, use, and disclose in our supply of the Portal. We may update this Privacy Policy from time to time. The most current version will be
  located on the Portal.
</p>
<p class="mb-4">
  By using the Portal or by providing any personal information to us, you consent to the collection, use and disclosure of your personal information as set out
  in this Privacy Policy.
</p>
<p class="mb-4">
  Please refer to the privacy policy of your Dental Care Provider (which may be accessible from a link on the Portal) for information on how your Dental Care
  Provider will hold, use and disclose personal information that is provided to the Dental Care Provider through the Portal.
</p>
<p class="mb-4">
  <strong class="block">Types of personal information collected</strong>
</p>
<p class="mb-4">
  The types of personal information that we collect will vary depending on what information has been requested by your Dental Care Provider. This information
  may include any or all of the following:
</p>
<ul class="mb-4">
  <li>name, address(es), telephone number(s) and other contact details.</li>
  <li>electronic address(es)</li>
  <li>payment information (such as credit card or bank details)</li>
  <li>medical history information</li>
</ul>
<p class="mb-4">
  <strong class="block">How we collect personal information</strong>
</p>
<p class="mb-4">We collect personal information using the following methods:</p>
<ul class="mb-4">
  <li>from you filling out forms on the Portal that we have provided at the request of your Dental Care Provider</li>
  <li>
    the Portal also interacts with the software used by your Dental Care Provider and accesses limited information from your dental records to assist you with
    the completion of subsequent forms on the Portal.
  </li>
</ul>
<p class="mb-4">
  You must not provide personal information of another person through the Portal unless you are authorised by that person to do so and you have ensured that the
  person is aware of and agrees to the matters set out in this Privacy Policy and the privacy policy of the Dental Care Provider. You warrant to us that you
  have permission to enter the personal information of that other person.
</p>

<p class="mb-4">
  <strong class="block">Why we collect, use and disclose personal information</strong>
</p>
<p class="mb-4">
  Personal information that we collect, process and disclose is only for the purpose of providing the service of collecting and transferring information between
  you and your Dental Care Provider and for the purposes set out in this Privacy Policy. We will not use your personal information provided to us for any other
  purpose unless authorised or required by law.
</p>
<p class="mb-4">We will use the personal information we collect and that you provide to us for the following purpose(s):</p>
<ul class="mb-4">
  <li>pre-populate forms requested by your Dental Care Provider;</li>
  <li>send completed forms to your Dental Care Provider;</li>
  <li>enable third parties to carry out technical, logistical or other functions on our behalf;</li>
  <li>improve our products and services.</li>
</ul>
<p class="mb-4">
  You do not need to provide us with your personal information, however if you do not, you may not be able to access the Portal or the associated services.
</p>

<p class="mb-4">
  <strong class="block">Direct marketing</strong>
</p>
<p class="mb-4">We do not have direct access to information that we collect, process, or disclose.</p>
<p class="mb-4">
  We will not use your personal information for any purposes other than to enable you to complete your forms and for the reasons set out in this Privacy Policy
  without your prior consent.
</p>
<p class="mb-4">Marketing consent forms on the Portal are for the relationship with your Dental Care Provider.</p>

<p class="mb-4">
  <strong class="block">How we store and protect personal information</strong>
</p>
<p class="mb-4">
  We store personal information in a combination of computer storage facilities. In so doing, we have taken numerous steps to protect your personal information
  from misuse, interference and loss, and unauthorised access, modification, or disclosure.
</p>
<p class="mb-4">
  Additionally, we take reasonable steps to destroy or permanently de-identify personal information when we no longer need it. Transfers of information are
  encrypted with https encryption and secure socket layer (SSL) technology.
</p>
<p class="mb-4">
  <strong class="block">Do we send personal information overseas?</strong>
</p>
<p class="mb-4">
  We will disclose your personal information, for the purposes described above, to organisations in Australia, New Zealand, the United Kingdom and the United
  States.
</p>
<p class="mb-4">
  Some of these countries may not have the same or substantially similar privacy laws as those set out in this privacy policy, the Australian Privacy Principles
  (APPs), the New Zealand Privacy Principles (NPPs) and applicable privacy laws including those relating to the handling of health information. We have strict
  contracts in place with suppliers to ensure data is protected and will take reasonable steps to ensure that your personal information is protected in a way
  that, is substantially comparable to the safeguards in this privacy policy, the APPs, the NPPs and other applicable privacy laws, including those relating to
  the handling of health information.
</p>
<p class="mb-4">
  <strong class="block">How you can access and correct your personal information</strong>
</p>
<p class="mb-4">
  To access personal information processed on the Portal you can log onto the Portal. If your personal details change, such as your address or phone number,
  please contact your Dental Care Provider or update the information on the Portal.
</p>
<p class="mb-4">
  Under the APPs and NPPs you have the right to request access to or correction of your information. You may access or correct your information via the Portal
  or by request to your Dental Care Provider. If you are unable to do so, you may request that we provide access to or correct your information directly by
  using the contact details set out in the “How to contact us” section below. We may need to verify your identity before providing access to or correcting your
  information. We will provide access to or correct your information if we are able to unless an exception under the relevant privacy laws apply. If we do not
  provide access to or correct your information, we will tell you why, unless it is unreasonable to do so, and provide you with information about how to make a
  complaint.
</p>
<p class="mb-4">We will respond to a request for access or correction within a reasonable period.</p>
<p class="mb-4">
  We have provided your Dental Care Provider with the ability to restrict data being processed on the Portal. If you would like to request this we advise you
  contact your Dental Care Provider.
</p>
<p class="mb-4">
  Personal information is retained on the Portal for no longer than necessary to allow you to complete your forms, complete patient check-in and review any
  post-appointment information. Completed forms are held by your Dental Care Provider with your dental records. There is no retention of information on this
  application when the processing activity is completed.
</p>
<p class="mb-4">
  <strong class="block">Cookies</strong>
</p>
<p class="mb-4">
  The Portal uses cookies to track user traffic patterns and to better serve you when you revisit a website. A cookie is a small data file that a website may
  write to your hard drive when you visit it. A cookie file can contain information, such as a user ID which the website uses to track the pages you have
  visited.
</p>
<p class="mb-4">
  The cookies in use do not track personal information entered on the Portal. We collect such information for statistical and maintenance purposes that enables
  us to continually evaluate our website performance. You can refuse cookies by turning them off in your browser. However, the Portal requires some cookies to
  function and so removing all cookies will stop the Portal from functioning.
</p>
<p class="mb-4">
  <strong class="block">How to contact us</strong>
</p>
<p class="mb-4">We would recommend that your first point of contact in all cases is your Dental Care Provider.</p>
<p class="mb-4">
  However, if you wish to make a complaint or an enquiry about our collection, use or disclosure of your personal information under this Privacy Policy, please
  contact:
</p>
<p class="mb-4">
  If you are based in Australia: <br />“The Privacy Officer” Henry Schein One Australia 3/39 Railway Road Blackburn, <br />VIC 3130 or
  <a href="mailto:admin@henryscheinone.com.au">admin&#64;henryscheinone.com.au</a>
</p>
<p class="mb-4">
  If you are based in New Zealand: <br />
  “The Privacy Officer” Henry Schein One New Zealand <br />219 Don Mckinnon Drive Albany, Auckland, 0632, New Zealand<br />
  <a href="mailto:admin@henryscheinone.co.nz">admin&#64;henryscheinone.co.nz </a>
</p>

<p class="mb-4">
  We will acknowledge and investigate any complaint about the way we manage personal information and will respond to your complaint within 10 working days or
  any time period required by applicable privacy laws including those relating to the handling of health information.
</p>
<p class="mb-4">We will take reasonable steps to remedy any issues resulting from our failure to comply with our privacy obligations.</p>
<p class="mb-4">
  <strong class="block">Last updated May 2023</strong>
</p>

<p class="mb-4">Please contact the practice for the privacy policy and terms and conditions.</p>
