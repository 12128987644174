import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";
import { PersistentObjectFactory, StringWrapper } from "../utils/persistent-object";
import { SupportedCountryCode, isSupportedCountryCode } from "@shared/localisation/localisation";
import { Regex } from "../utils/regex";
import { LoggerService } from "./logger.service";

export interface I_PreferencesStrings {
  unsubscribing: {
    heading: string;
    paragraph: string;
  };
  subscribing: {
    heading: string;
    paragraph: string;
  };
  unsubscribed: {
    heading: string;
    subheading: string;
    paragraph: string;
    button: string;
  };
  subscribed: {
    heading: string;
    subheading: string;
    paragraph: string;
    button: string;
  };
}

@Injectable({
  providedIn: "root",
})
export class LocalisationService {
  private _persistentCountryCode: StringWrapper<SupportedCountryCode>;
  constructor(private _cacheService: CacheService, private _loggerService: LoggerService) {
    const pof = new PersistentObjectFactory(this._cacheService, "countryCode");
    pof.ttlDuration = undefined; //never expire this cache
    this._persistentCountryCode = pof.create(new StringWrapper());
  }

  /*
    Dentally/db    |     UK         |     AU         |      NZ        | Can
    address_line_1 | Address Line 1 | Address Line 1 | Address Line 1 | Address Line 1
    address_line_2 | Address Line 2 | Address Line 2 | Address Line 2 | Address Line 2
    address_line_3 | Address Line 3 | Suburb         | Suburb         | Address Line 3
    town           | Town           | City           | City           | City
    county         | County         | State          | Region         | Province
    postcode       | Post Code      | Post Code      | Post Code      | Post Code
  */

  public set country_code(code: string) {
    if (!isSupportedCountryCode(code)) {
      const errorMessage = `Trying to set unsupported country code in localisation service: ${code}`;
      this._loggerService.notifyBugsnagOnce("4d405f9b", errorMessage, { code });
      return;
    }
    this._persistentCountryCode.value = code;
  }

  public get country_code(): SupportedCountryCode {
    if (!this._persistentCountryCode.value) {
      const errorMessage = `Trying to get country code but it is not set`;
      //throw new Error(errorMessage);
      //for now, notify bugsnag instead of throwing, as if domains/info has been cached this won't be set.
      this._loggerService.notifyBugsnagOnce("03f4d20a", errorMessage);
    }

    return this._persistentCountryCode.value;
  }

  public get addressLine3Label(): string {
    switch (this.country_code) {
      case "AU":
      case "NZ":
        return "Suburb";

      default:
        return "Address line 3";
    }
  }

  public get townLabel(): string {
    switch (this.country_code) {
      case "AU":
      case "NZ":
      case "CA":
        return "City";

      default:
        return "Town";
    }
  }

  public get countyLabel(): string {
    switch (this.country_code) {
      case "AU":
        return "State";
      case "NZ":
        return "Region";
      case "CA":
        return "Province";

      default:
        return "County";
    }
  }

  public get isPostcodeOptional(): boolean {
    return this.country_code.includes("IE");
  }

  public get preferences(): I_PreferencesStrings {
    const processingParagraph = "Please wait while we process your request.";
    const strings: I_PreferencesStrings = {
      unsubscribing: {
        heading: "Unsubscribing",
        paragraph: processingParagraph,
      },
      subscribing: {
        heading: "Subscribing",
        paragraph: processingParagraph,
      },
      unsubscribed: {
        heading: "You have successfully unsubscribed from our marketing emails.",
        subheading: "We're sorry to see you go",
        paragraph: "",
        button: "Subscribe",
      },
      subscribed: {
        heading: "You have successfully subscribed to our marketing emails.",
        subheading: "Thank you for subscribing",
        paragraph: "",
        button: "Unsubscribe",
      },
    };

    if (this.country_code === "GB") {
      strings.unsubscribed.paragraph = "We'll continue sending you appointment reminders and important information about your dental treatment.";
      strings.subscribed.paragraph =
        "We'll send you marketing messages along with appointment reminders and important information about your dental treatment.";
    } else {
      strings.unsubscribed.paragraph = "We'll continue sending you important information about your dental treatment.";
      strings.subscribed.paragraph = "We'll send you marketing messages along with important information about your dental treatment.";
    }

    return strings;
  }

  public get phoneNumberRegex(): RegExp {
    return Regex.PHONE_NUMBER[this.country_code] || Regex.PHONE_NUMBER.Default;
  }
}
