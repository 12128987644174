import { Injectable } from "@angular/core";
import { CacheService } from "./cache.service";
import { Constants } from "src/constants";
import omit from "lodash/omit";
import { JWTService, PUBLIC_IN_PRACTICE_JWT_STORAGE_KEY } from "./jwt.service";
import { WindowService } from "./window.service";
import Bugsnag from "@bugsnag/js";
import jwtDecode from "jwt-decode";
import { notifyAndLeaveBreadcrumb } from "../utils/logging";
import { SessionService } from "./session.service";

export interface I_PipData {
  practiceName: string;
  region: string;
  siteId: string;
  siteName: string;
  siteUrl: string;
  stage: string;
  jwt: string;
}

@Injectable({
  providedIn: "root",
})
export class PortalInPracticeDataService {
  public get publicInPracticeToken(): Record<string, any> | null {
    try {
      const token = this._cacheService.get(PUBLIC_IN_PRACTICE_JWT_STORAGE_KEY);
      if (!token) return null;
      return jwtDecode(token);
    } catch (e) {
      return null;
    }
  }
  constructor(
    private _cacheService: CacheService,
    private _jwtService: JWTService,
    private _windowService: WindowService,
    private _sessionService: SessionService
  ) {}

  public setPipData(data: I_PipData) {
    this._cacheService.set(Constants.PIP_DATA_STORAGE_KEY, JSON.stringify(omit(data, "jwt")));
  }

  public getPipData(): I_PipData | null {
    const data = this._cacheService.get(Constants.PIP_DATA_STORAGE_KEY);
    if (!data) return null;
    return JSON.parse(data) as I_PipData;
  }

  public hasPipData(): boolean {
    return this.getPipData() !== null;
  }

  public deletePipData(): void {
    this._cacheService.delete(Constants.PIP_DATA_STORAGE_KEY);
  }

  public updatePipDataSiteUrl(siteUrl: string): void {
    const pipDataString = this._cacheService.get(Constants.PIP_DATA_STORAGE_KEY);
    if (!pipDataString) throw new Error("Can't update site url without existing PiP data");
    const pipData = JSON.parse(pipDataString) as I_PipData;
    pipData.siteUrl = siteUrl;

    this._cacheService.set(Constants.PIP_DATA_STORAGE_KEY, JSON.stringify(pipData));
  }

  public selfHealDevice(): void {
    try {
      if (!this._jwtService.isPip()) {
        this._jwtService.restorePublicInPracticeToken();

        if (!this._jwtService.isPip() || this._jwtService.hasExpired) {
          throw new Error("Failed to self heal device");
        }
      }
    } catch (error) {
      notifyAndLeaveBreadcrumb("Failed to self heal device", { error });

      this._removePublicInPracticeToken();
      this.deletePipData();
      this._sessionService
        .clear()
        .then(() => {
          this._windowService.reload();
        })
        .catch(() => {
          Bugsnag.notify("Failed to clear session");
        });

      throw error;
    }
  }

  private _removePublicInPracticeToken(): void {
    this._cacheService.delete(PUBLIC_IN_PRACTICE_JWT_STORAGE_KEY);
  }
}
