import { SHARED } from "src/app/shared/shared";
import { Component, Input, OnDestroy, OnInit, Type } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { SubSink } from "subsink";
import { ModalComponent } from "../../modal/modal.component";
import { LoginTermsDefaultComponent } from "./login-terms/login-terms-default/login-terms-default.component";
import { LoginTermsAUComponent } from "./login-terms/login-terms-au/login-terms-au.component";
import { LoginTermsCAComponent } from "./login-terms/login-terms-ca/login-terms-ca.component";

type LoginTermsComponentType = Type<LoginTermsCAComponent | LoginTermsAUComponent | LoginTermsDefaultComponent>;

@Component({
  selector: "dentr-login-terms",
  templateUrl: "./login-terms.component.html",
  standalone: true,
  imports: [ModalComponent, SHARED],
})
export class LoginTermsComponent implements OnInit, OnDestroy {
  @Input()
  public is_modal = true;
  private _subs = new SubSink();
  public iso_country_code = "GB"; // GB is the default region
  constructor(private _commonService: CommonService, private _overlayService: OverlayService) {
    this.iso_country_code = this._commonService.practice.iso_country_code;
  }

  private _loginTermsMap: Record<string, LoginTermsComponentType> = {
    CA: LoginTermsCAComponent,
    AU: LoginTermsAUComponent,
    NZ: LoginTermsAUComponent,
  };

  ngOnInit() {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public closeModal(): void {
    this._overlayService.close();
  }

  get selectedTermsComponent(): LoginTermsComponentType {
    return this._loginTermsMap[this.iso_country_code] || LoginTermsDefaultComponent;
  }
}
