import { Component } from "@angular/core";
import { SHARED } from "src/app/shared/shared";

@Component({
  selector: "dentr-privacy-policy-default",
  templateUrl: "./privacy-policy-default.component.html",
  standalone: true,
  imports: [SHARED],
})
export class PrivacyPolicyDefaultComponent {}
