import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Routes, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { LoginV4Service } from "../shared/services/login-v4.service";
import { JWTService } from "../shared/services/jwt.service";
import { switchMap } from "rxjs/operators";
import { LoginRouteAnimationResolver } from "../shared/resolvers/login-route-animation.resolver";
import { SessionService } from "../shared/services/session.service";
import { NavigationService } from "../shared/services/navigation.service";
import { LocationService } from "../shared/services/location.service";
import { loadComponentWithErrorHandling } from "../shared/utils/routing";

@Injectable({
  providedIn: "root",
})
export class CanActivateLogin {
  constructor(
    private _jwtService: JWTService,
    private _loginService: LoginV4Service,
    private _locationService: LocationService,
    private _navigationService: NavigationService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this._jwtService.isPatientUnauthenticated() && state.url === "/login" && this._jwtService.canLogin) {
      // Go to the correct part in the login flow if the patient is unauthenticated (i.e. we know who they are but need them to login)
      return this._loginService.redirectUnauthenticatedPatientToLogin("/my-dental").pipe(
        switchMap(() => {
          return of(false);
        })
      );
    }

    return of(this._jwtService.canLogin);
  }
}

@Injectable()
export class ReEnterPasswordGuard {
  constructor(private _jwtService: JWTService, private _sessionService: SessionService, private _navigationService: NavigationService) {}

  canActivate(): boolean {
    if (!this._jwtService.isLoggedIn() || !this._sessionService.isPasswordRequired) {
      this._navigationService.navigate("/");

      return false;
    }

    return true;
  }

  canDeactivate(): boolean {
    return !this._sessionService.isPasswordRequired;
  }
}

export const LOGIN_ROUTES: Routes = [
  {
    path: "",
    loadComponent: loadComponentWithErrorHandling(() => import("./login-v4.component").then((m) => m.LoginV4Component)),
    children: [
      {
        path: "",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-enter-userkey/login-enter-userkey.component").then((m) => m.LoginEnterUserkeyComponent)
        ),
        canActivate: [CanActivateLogin],
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "redirect",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-authorize-password/login-authorize-password.component").then((m) => m.LoginAuthorizePasswordComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "enter-mobile",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-enter-userkey/login-enter-userkey.component").then((m) => m.LoginEnterUserkeyComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "enter-pass",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-enter-pass/login-enter-pass.component").then((m) => m.LoginEnterPassComponent)),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "secure-account",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-secure-account/login-secure-account.component").then((m) => m.LoginSecureAccountComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "create-pass",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-create-password/login-create-password.component").then((m) => m.LoginCreatePasswordComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "magic-link-sent",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-magic-link-sent/login-magic-link-sent.component").then((m) => m.LoginMagicLinkSentComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "reset-pass",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-create-password/login-create-password.component").then((m) => m.LoginCreatePasswordComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "verify-pass/:guid",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-enter-pass/login-enter-pass.component").then((m) => m.LoginEnterPassComponent)),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "reset-password",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-authorize-password/login-authorize-password.component").then((m) => m.LoginAuthorizePasswordComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "find-record",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-find-record/login-find-record.component").then((m) => m.LoginFindRecordComponent)),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "one-last-thing",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-one-last-thing/login-one-last-thing.component").then((m) => m.LoginOneLastThingComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "create-account",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-authorize-password/login-authorize-password.component").then((m) => m.LoginAuthorizePasswordComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "confirm-details",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-confirm-details/login-confirm-details.component").then((m) => m.LoginConfirmDetailsComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "verify",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-verify/login-verify.component").then((m) => m.LoginVerifyComponent)),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "verify-max-attempts",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-verify-max-attempts/login-verify-max-attempts.component").then((m) => m.LoginVerifyMaxAttemptsComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "verify-short-code",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-verify/login-verify.component").then((m) => m.LoginVerifyComponent)),
        data: {
          shortCode: true,
        },
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "error-verify-record",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-error-verify-record/login-error-verify-record.component").then((m) => m.LoginErrorVerifyRecordComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "error-verify-identity",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-error-verify-identity/login-error-verify-identity.component").then((m) => m.LoginErrorVerifyIdentityComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "error-generic",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-error-generic/login-error-generic.component").then((m) => m.LoginErrorGenericComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "accept-terms",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-terms/login-terms.component").then((m) => m.LoginTermsComponent)),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "help",
        loadComponent: loadComponentWithErrorHandling(() => import("./login-help/login-help.component").then((m) => m.LoginHelpComponent)),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "account-locked",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-account-locked/login-account-locked.component").then((m) => m.LoginAccountLockedComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "already-logged-in",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./login-already-logged-in/login-already-logged-in.component").then((m) => m.LoginAlreadyLoggedInComponent)
        ),
        resolve: {
          animation: LoginRouteAnimationResolver,
        },
      },
      {
        path: "re-enter-password",
        loadComponent: loadComponentWithErrorHandling(() => import("./re-enter-password/re-enter-password.component").then((m) => m.ReEnterPasswordComponent)),
        data: {
          hideTopNav: true,
          preventLoginGoHome: true,
        },
        canActivate: [ReEnterPasswordGuard],
        canDeactivate: [ReEnterPasswordGuard],
      },
    ],
  },
];
