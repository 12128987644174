<h2 class="heading-xxl mb-4">Patient portal Terms of Use</h2>
<p class="mb-4">
  <strong class="block">PLEASE NOTE THESE TERMS AND CONDITIONS APPLY TO YOUR USE OF THIS PORTAL</strong>
</p>
<p class="mb-4">
  This online portal (“<strong>Portal</strong>”) collects information, including personal information, on behalf of your dental care provider, which includes
  both dental practices and referral centres, where applicable (“<strong>Dental Care Provider</strong>”). The Portal also interacts with the software used by
  your Dental Care Provider to access certain personal information from your dental records hosted on the software for the purposes of verifying your identity
  and to enable your use of functions of the Portal, including scheduling appointments, viewing your information and the completion of subsequent forms on the
  Portal.
</p>
<p class="mb-4">
  The Portal is a platform supplied to your Dental Care Provider who has contracted with Henry Schein One Canada (the “<strong>Portal Provider</strong>”) to
  provide the Portal on its behalf.
</p>
<ul class="mb-4">
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">1.</span>
    <div>
      <p class="mb-4">
        <strong>By using the Portal you accept these terms.</strong> By using the Portal, you confirm that you accept these terms of use (“<strong
          >Portal Terms</strong
        >”) in relation to your use of the Portal and you are entering into an agreement with the Portal Provider.
      </p>
      <p class="mb-4">If you do not agree to the Portal Terms, you must not use the Portal.</p>
    </div>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">2.</span>
    <div>
      <p class="mb-4">
        <strong>Limited End User License and Intellectual Property Rights</strong> The Portal Provider hereby grants to you a limited, revocable, personal,
        non-sub-licensable, non-transferable, non-exclusive right to access and use the Portal in accordance with the Portal Terms and not for resale or for any
        other purpose. The term of your license commences on the date of your acceptance of the Portal Terms and shall end on the earlier date of your, your
        Dental Care Provider’s or our suspension or termination of your access to the Portal.
      </p>
      <p class="mb-4">
        You acknowledge and agree that, save and except for your personal information contained in the Portal and the license explicitly granted by the Portal
        Terms, all right, title and interest in and to the Portal is the sole and exclusive property of the Portal Provider. The Portal Provider reserves all
        rights not expressly granted to you hereunder, and for greater certainty, the Portal Provider shall retain all intellectual property and other
        proprietary rights in and to the Portal.
      </p>
      <p class="mb-4">
        You acknowledge that Portal exclusively owns all rights, titles and interests in and to the services and content contained herein, including without
        limitation, all underlying software and technology, and all associated intellectual property rights in relation to the Portal other than that owned by
        third parties and your personal information. By accessing and using the Portal, you acknowledge all content is protected copyright, trademark, design
        rights or other proprietary rights incorporated in the services and content.
      </p>
    </div>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">3.</span>
    <span
      ><strong>Handling of your personal information and Portal Provider’s Privacy Policy.</strong> By entering your personal information into the Portal, you
      expressly consent to the collection, use, transfer and retention of your personal information by the Portal Provider in accordance with the Portal
      Provider’s privacy policy, which can be found <a class="link" href="#privacy">here</a> (“<strong>Privacy Policy</strong>”).
    </span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">4.</span>
    <span
      ><strong>Dental Care Provider.</strong> Information entered into the Portal will be transferred to your Dental Care Provider. By providing your personal
      information to the Portal Provider, you acknowledge that you have reviewed your Dental Care Provider’s terms and conditions and privacy policy applicable
      to the services being provided by your Dental Care Provider and consent to the Portal Provider’s collection, use and transfer of your personal information
      for these purposes. Please refer to your Dental Care Provider’s terms and conditions and privacy policy for details as to how the Dental Care Provider may
      use your personal information, your rights to request access to, rectification, erasure or restriction of processing and how to contact them regarding
      your legal rights. Your Dental Care Provider remains responsible for your dental records and ensuring they comply with their legal and professional
      obligations.
    </span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">5.</span>
    <div>
      <p class="mb-4">
        <strong>Adding Your Information to the Portal. </strong> Whenever you make use of a feature that allows you to enter your information into the Portal,
        you must comply with the standards set out below. Any information provided must to your knowledge:
      </p>
      <ul class="mb-4 ml-4 list-disc">
        <li class="mb-2">be current, true, accurate (where it states facts), supportable and complete; and</li>
        <li>comply with applicable Canadian laws and the applicable laws in any country from which it is posted.</li>
      </ul>
      <p class="mb-4">
        You acknowledge that any information you supply does comply with the above, and that you will be responsible for any information supplied.
      </p>
      <p class="mb-4">
        Your information may also be entered into the Portal by family members, legal guardians or any other person legally authorized to provide your personal
        information to us and your Dental Care Provider.
      </p>
      <p class="mb-4">
        You must not provide the information of another person, including their personal information, unless you are legally authorized to do so. You warrant to
        us that you have the requisite legal authorization to enter the personal information of the other person.
      </p>
    </div>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">6.</span>
    <span
      ><strong>Accuracy and Completeness of Information.</strong> The Portal Provider is not responsible if the information you provide through the Portal or
      the information accessed by the Dental Care Provider’s software is not accurate, complete or current. If the information on the Portal is not accurate,
      complete or current, please update your information in the Portal and / or contact your Dental Care Provider.
    </span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">7.</span>
    <span
      ><strong>Usage Details.</strong> This platform has the capability to track anonymized usage details to assist with product improvements. Should you wish
      to disable this capability, please ensure you clear cookies from your browser and re-review the cookie consent.</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">8.</span>
    <span
      ><strong>You must keep your access credentials safe. </strong> If you choose, or you are provided with, a user identification code, password or any other
      piece of information as part of our security procedures (such as a link in an email), you must treat such information as confidential. If you know or
      suspect that anyone other than you, or a designated confidant, has accessed the Portal you must immediately notify your Dental Care Provider
      immediately.</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">9.</span>
    <span
      ><strong>Dental Care Provider websites. </strong> Where the Portal contains links to other websites and resources provided by your Dental Care Provider,
      your Dental Care Provider is solely responsible for any websites associated with the Portal.
    </span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">10.</span>
    <div>
      <p class="mb-4">
        <span><strong>Prohibited uses of the Portal. </strong> You may use the Portal only for lawful purposes. You may not use the Portal:</span>
      </p>
      <ul class="mb-4 ml-4 list-disc">
        <li class="mb-2">in any way that breaches any applicable local, national or international law or regulation;</li>
        <li class="mb-2">in any way that is fraudulent;</li>
        <li class="mb-2">harm or with the attempt to harm another person in any way;</li>
        <li class="mb-2">
          to knowingly transmit any data, send or upload any harmful programs or similar computer code designed to adversely affect the operation of any
          computer software or hardware;
        </li>
        <li class="mb-2">to infringe any other person’s intellectual property or privacy rights; or</li>
        <li class="mb-2">to breach any legal duty owed to a third-party, such as a contractual duty or a duty of confidence.</li>
      </ul>
      <p class="mb-4">You also agree:</p>
      <ul class="mb-4 ml-4 list-disc">
        <li class="mb-2">
          not to access without authority, interfere with, damage or disrupt any part of the Portal or any hardware or software in relation to it;
        </li>
        <li class="mb-2">
          you will not misuse the Portal by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically
          harmful;
        </li>
        <li class="mb-2">
          you will not attempt to gain unauthorized access to the Portal, the server on which the Portal is stored, or any server, computer or database
          connected to the Portal; and
        </li>
        <li>you will not attack the Portal.</li>
      </ul>
    </div>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">11.</span>
    <span
      ><strong>Other terms relating to the Portal.</strong> Please note that these Portal Terms, their subject matter and their formation, are governed by the
      laws of Canada. We may make changes to these terms and to the Portal from time to time.
    </span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">12.</span>
    <span
      ><strong>How to relay questions or comments regarding the Portal? </strong> If you have any questions or comments regarding your use of the Portal, please
      contact your Dental Care Provider.</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">13.</span>
    <div>
      <p class="mb-4">
        <span
          ><strong>Limited Warranty and Liability.</strong> We will make reasonable commercial efforts to make the Portal available and to ensure it operates as
          described. Other than the foregoing warranty, to the maximum extent permitted by law, the Portal Provider provides the Portal and related services on
          an "as is, as available" basis without any warranties, representations, or guarantees of any kind, including that the Portal will be uninterrupted or
          error free or that it is fit for a particular purpose.</span
        >
      </p>
      <p class="mb-4">
        To the extent permitted by law, the Portal Provider is not liable under the law of tort, contract or otherwise for any direct or indirect loss or
        damage, personal injury, loss of income, profits, data or savings or for any, incidental, consequential, exemplary, punitive or special loss or damage
        of any party, however caused, arising out of or in connection with the use of the Portal or in connection with these Portal Terms.
      </p>
      <p class="mb-4">These Limited Warranty and Liability terms shall survive termination of the Portal Terms.</p>
    </div>
  </li>

  <li class="mb-4 flex">
    <span class="mr-2 font-bold">14.</span>
    <span
      ><strong>No Clinical Services.</strong> By allowing access and use of the Portal, the Portal Provider is not providing any medical or dental advice,
      diagnosis or treatment (“<strong>Clinical Services</strong>”). Any Clinical Services are provided solely by your Dental Care Provider who is solely
      responsible for the Clinical Services they provide to you.</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">15.</span>
    <div>
      <p class="mb-4">
        <span
          ><strong>Termination of Access by Portal Provider.</strong> The Portal Provider may terminate your access to the Portal (either in whole or in part)
          for any reason including:</span
        >
      </p>
      <ul class="mb-4 ml-4 list-disc">
        <li class="mb-2">if you breach any of these Portal Terms;</li>
        <li class="mb-2">
          if the Portal Provider’s agreement with the Dental Care Provider is terminated or any fees payable for the use of the Portal have not been paid by the
          Dental Care Provider responsible for the payment of the fees;
        </li>
        <li class="mb-2">
          if the Portal Provider considers it reasonably necessary to protect the Portal, the Portal Provider’s systems or any other third party using the
          Portal; or
        </li>
        <li>the Portal Provider ceases to provide the Portal services generally.</li>
      </ul>
      <p>If your access to the Portal is terminated, please contact your Dental Care Provider for access to your dental records.</p>
    </div>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">16.</span>
    <span
      ><strong>Termination by you.</strong> You may terminate these Portal Terms at any time by ceasing to use the Portal or by contacting your Dental Care
      Provider to request that the Portal Provider cease processing your personal information.</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">17.</span>
    <span
      ><strong>Waiver.</strong> No failure to exercise, and no delay in exercising, any right or any power under the Portal Terms by the Portal Provider will
      operate as a waiver thereof.</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">18.</span>
    <span
      ><strong>Severability.</strong> If any portion of these Portal Terms is found to be invalid or unenforceable by any court of competent jurisdiction, the
      other provisions of the Portal Terms shall remain in full force and effect.</span
    >
  </li>
</ul>
