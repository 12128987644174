<dentr-modal>
  <h2 class="heading-xl mb-4">Diagnostics data</h2>
  @if (!diagnosticsData.length) {
  <dentr-skeleton-loader [repeat]="3">
    <ng-template let-item>
      <dentr-skeleton-default size="sm"></dentr-skeleton-default>
    </ng-template>
  </dentr-skeleton-loader>
  } @else {
  <dl class="space-y-3">
    @for (item of diagnosticsData; track item.key) {
    <div class="flex flex-col sm:flex-row">
      <dt class="mr-3 flex-grow whitespace-nowrap text-sm font-semibold">{{ item.key }}:</dt>
      <dd class="text-sm">{{ item.value }}</dd>
    </div>
    }
    <div class="flex flex-col sm:flex-row">
      <dt class="mr-3 flex-grow whitespace-nowrap text-sm font-semibold">Current Time:</dt>
      <dd class="text-sm"><dentr-clock></dentr-clock></dd>
    </div>
  </dl>
  }
</dentr-modal>
