import { PatientSmileGoalBase } from "../../graph/patient_smile_goal/patient-smile-goal-base";
import { E_PatientDetailsSection } from "../../graph/patient-details/patient-details-base";
import { T_CommsMethod } from "../../graph/patient-short-codes/patient-short-code-base";
import { E_Patient_Actions_Type, E_Restricted_Patient_Actions_Type } from "../../graph/patient_actions/patient-action-base";
import { E_Referrer } from "../enums/referrer";
import { I_Referrer } from "../interfaces/referrer";
import { Metric, _getTaskReminderHourSent } from "./metric";
import { PipMetrics as PipMetricsNS } from "./pip-metrics";
import { E_ActionType } from "../../graph/patients/patient-base";

export const PERFORMANCE_DASHBOARD_METRIC_NAMES = {
  APPOINTMENT_BOOKED: "Appointment Booked BE",
  APPOINTMENT_CANCELLED: "Appointment Cancelled",
  ACTION_DONE: "Action Done",
};

export const PATIENT_DETAILS_SECTION_METRIC_NAMES = {
  [E_PatientDetailsSection.Address]: "Address",
  [E_PatientDetailsSection.AcquisitionSource]: "Acquisition Source",
  [E_PatientDetailsSection.Email]: "Email",
  [E_PatientDetailsSection.EmergencyContact]: "Emergency Contact",
  [E_PatientDetailsSection.PatientGpDetails]: "GP Details",
  [E_PatientDetailsSection.Phone]: "Phone",
  [E_PatientDetailsSection.Marketing]: "Marketing",
  [E_PatientDetailsSection.Name]: "Name",
};

export const ACTION_TYPE_METRIC_NAMES = {
  // PMS Actions
  [E_ActionType.FP17]: E_Patient_Actions_Type.NHS_PR, // So we dont lose old metrics keep the old mapping
  [E_ActionType.MedicalHistory]: E_Patient_Actions_Type.MEDICAL_HISTORY, // So we dont lose old metrics keep the old mapping
  [E_ActionType.GP17Part1]: "GP17PR",
  [E_ActionType.GP17Part2]: "GP17PR",
  [E_ActionType.PendingPayment]: "Pending Payment",
  [E_ActionType.SmileGoal]: "Smile Goal",

  // Portal derived actions
  [E_Patient_Actions_Type.CHECK_IN]: E_Patient_Actions_Type.CHECK_IN,
  [E_Patient_Actions_Type.PATIENT_DETAILS]: E_Patient_Actions_Type.PATIENT_DETAILS,
  [E_Patient_Actions_Type.TREATMENT_PLAN_ESTIMATE]: E_Patient_Actions_Type.TREATMENT_PLAN_ESTIMATE,
  [E_Patient_Actions_Type.PAYMENT]: E_Patient_Actions_Type.PAYMENT,
  [E_Patient_Actions_Type.ROUTINE_APPT]: E_Patient_Actions_Type.ROUTINE_APPT,
  [E_Patient_Actions_Type.ROUTINE_DENTAL_EXAM]: E_Patient_Actions_Type.ROUTINE_DENTAL_EXAM,
  [E_Patient_Actions_Type.ROUTINE_HYGIENE_APPT]: E_Patient_Actions_Type.ROUTINE_HYGIENE_APPT,
};

export const OTHER_METRIC_NAMES = {
  ACTION_DUE: "Action Due",
  ACTION_OPENED: "Action Opened",
  ACTION_SKIPPED: "Action Skipped",
  ACTION_SIGNED: "Action Signed",
};

export enum E_APP {
  PATIENT = "patient",
  MANAGE = "manage",
}
export class PaymentMetricProperties {
  public referrer: I_Referrer;
  public amount: number;
  public payment_method?: string;
  public type: E_Patient_Actions_Type;
}

export { Metric };
export namespace METRIC {
  export class ActionDue extends Metric {
    constructor(
      site_id: string,
      type: E_Patient_Actions_Type | E_ActionType,
      referrer: I_Referrer,
      actions_displayed: number,
      task_reminder_sent_at?: Date | null
    ) {
      const properties: Record<string, any> = {
        referrer,
        actions_displayed,
        type: ACTION_TYPE_METRIC_NAMES[type],
      };

      if (task_reminder_sent_at) properties.hour_sent = _getTaskReminderHourSent(task_reminder_sent_at);

      super(OTHER_METRIC_NAMES.ACTION_DUE, site_id, properties);
    }
  }

  export class ActionDue_PatientDetails extends Metric {
    constructor(site_id: string, referrer: I_Referrer, actions_displayed: number, section: E_PatientDetailsSection) {
      const properties: Record<string, any> = {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PATIENT_DETAILS],
        referrer,
        actions_displayed,
        section: PATIENT_DETAILS_SECTION_METRIC_NAMES[section],
      };

      super(OTHER_METRIC_NAMES.ACTION_DUE, site_id, properties);
    }
  }

  export class ActionDue_TreatmentPlanEstimate extends Metric {
    constructor(site_id: string, referrer: I_Referrer, actions_displayed: number, template_name: string) {
      const properties: Record<string, any> = {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.TREATMENT_PLAN_ESTIMATE],
        referrer,
        actions_displayed,
        template_name,
      };

      super(OTHER_METRIC_NAMES.ACTION_DUE, site_id, properties);
    }
  }

  export class ActionDone extends Metric {
    constructor(site_id: string, type: E_Patient_Actions_Type, referrer: I_Referrer) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, site_id, { type, referrer });
    }
  }

  export class ActionDone_PatientDetails extends Metric {
    constructor(site_id: string, referrer: I_Referrer, section: E_PatientDetailsSection) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PATIENT_DETAILS],
        referrer,
        section: PATIENT_DETAILS_SECTION_METRIC_NAMES[section],
      });
    }
  }

  export class ActionDone_TreatmentPlanEstimate extends Metric {
    constructor(site_id: string, referrer: I_Referrer, template_name: string) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.TREATMENT_PLAN_ESTIMATE],
        referrer,
        template_name,
      });
    }
  }

  export class ActionDone_SmileGoal extends Metric {
    constructor(site_id: string, referrer: I_Referrer, smile_goal: PatientSmileGoalBase) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_ActionType.SmileGoal],
        referrer,
        smile_goal,
      });
    }
  }

  export class ActionDue_NhsPr extends Metric {
    constructor(site_id: string, type: E_ActionType, referrer: I_Referrer, actions_displayed: number, task_reminder_sent_at?: Date | null) {
      const properties: Record<string, any> = { type: ACTION_TYPE_METRIC_NAMES[type], referrer, actions_displayed, form: type };

      if (task_reminder_sent_at) properties.hour_sent = _getTaskReminderHourSent(task_reminder_sent_at);

      super(OTHER_METRIC_NAMES.ACTION_DUE, site_id, properties);
    }
  }

  export class ActionOpened_NhsPr extends Metric {
    constructor(site_id: string, type: E_ActionType, referrer: I_Referrer) {
      super(OTHER_METRIC_NAMES.ACTION_OPENED, site_id, { type: ACTION_TYPE_METRIC_NAMES[type], referrer, form: type });
    }
  }

  export class ActionDone_NhsPr extends Metric {
    constructor(site_id: string, referrer: I_Referrer, signature_used: true, type: E_ActionType) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, site_id, { type: ACTION_TYPE_METRIC_NAMES[type], referrer, signature_used, form: type });
    }
  }

  export class ActionSigned_NhsPr extends Metric {
    constructor(site_id: string, referrer: I_Referrer, type: E_ActionType) {
      super(OTHER_METRIC_NAMES.ACTION_SIGNED, site_id, { type: ACTION_TYPE_METRIC_NAMES[type], referrer, form: type });
    }
  }

  export class ActionDone_MedicalHistory extends Metric {
    constructor(site_id: string, referrer: I_Referrer, signature_used: true) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_ActionType.MedicalHistory],
        referrer,
        signature_used,
      });
    }
  }

  export class ActionLinkGenerated extends Metric {
    constructor(
      region: string,
      stage: string,
      site_id: string,
      type: E_Patient_Actions_Type | E_Restricted_Patient_Actions_Type | E_ActionType,
      comms_method: T_CommsMethod
    ) {
      super("Action Link Generated", site_id, { stage, type: ACTION_TYPE_METRIC_NAMES[type], comms_method });
      //action links are generated in the global stack, so need to set region and stage
      //so we know where to raise the metrics
      this.region = region;
    }
  }

  export class ActionOpened extends Metric {
    constructor(site_id: string, type: E_Patient_Actions_Type | E_ActionType, referrer: I_Referrer) {
      super(OTHER_METRIC_NAMES.ACTION_OPENED, site_id, { type: ACTION_TYPE_METRIC_NAMES[type], referrer });
    }
  }

  export class ActionOpened_TreatmentPlanEstimate extends Metric {
    constructor(site_id: string, referrer: I_Referrer, template_name: string) {
      super(OTHER_METRIC_NAMES.ACTION_OPENED, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.TREATMENT_PLAN_ESTIMATE],
        referrer,
        template_name,
      });
    }
  }

  export class ActionOpened_MedicalHistory extends Metric {
    constructor(site_id: string, referrer: I_Referrer) {
      super(OTHER_METRIC_NAMES.ACTION_OPENED, site_id, { type: ACTION_TYPE_METRIC_NAMES[E_ActionType.MedicalHistory], referrer });
    }
  }

  export class ActionSigned_MedicalHistory extends Metric {
    constructor(site_id: string, referrer: I_Referrer) {
      super(OTHER_METRIC_NAMES.ACTION_SIGNED, site_id, { type: ACTION_TYPE_METRIC_NAMES[E_ActionType.MedicalHistory], referrer });
    }
  }

  export class ActionOpened_PatientDetails extends Metric {
    constructor(site_id: string, referrer: I_Referrer, section: E_PatientDetailsSection) {
      super(OTHER_METRIC_NAMES.ACTION_OPENED, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PATIENT_DETAILS],
        referrer,
        section: PATIENT_DETAILS_SECTION_METRIC_NAMES[section],
      });
    }
  }

  export class ActionSkipped_PatientDetails extends Metric {
    constructor(site_id: string, referrer: I_Referrer, section: E_PatientDetailsSection) {
      super(OTHER_METRIC_NAMES.ACTION_SKIPPED, site_id, {
        type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PATIENT_DETAILS],
        referrer,
        section: PATIENT_DETAILS_SECTION_METRIC_NAMES[section],
      });
    }
  }

  /**
   * The BE metric is used for dashboards, but is not sent to mixpanel as the FE has more properties.
   * We need to have both because we can't use the FE for dashboards as it is not as accurate/can be artificially
   * increased by users (e.g. by repeatedly resending the track request).
   */
  export class AppointmentBooked_BE extends Metric {
    constructor(site_id: string) {
      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.APPOINTMENT_BOOKED, site_id, { timestream_only: true });
    }
  }

  export class AppointmentBookingFailed extends Metric {
    constructor(site_id: string, errorMessage: string, action: "create" | "update", item: Record<string, any>) {
      super("Appointment Booking Failed", site_id, { errorMessage, action, ...item });
    }
  }

  export class AppointmentCancelled extends Metric {
    constructor(site_id: string, hours_until_appointment: number, appointment_reason: string | null) {
      const setProperties: Record<string, any> = { hours_until_appointment };
      if (appointment_reason) setProperties.appointment_reason = appointment_reason;

      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.APPOINTMENT_CANCELLED, site_id, setProperties);
    }
  }

  export class DentallyRecordSearched extends Metric {
    constructor(found: "none" | "single" | "multiple") {
      //Site id is not known - they haven't logged in yet
      super("Dentally Record Searched", null, { found });
    }
  }

  export class PasswordInvalid extends Metric {
    constructor() {
      super("Password Invalid", null);
    }
  }

  export class PatientLoggedIn extends Metric {
    constructor(site_id: string, new_registration: boolean) {
      super("Patient Logged In", site_id, { new_registration });
    }
  }

  export class PatientLoginStarted extends Metric {
    constructor() {
      //Site id is not known - they could be logging in to any site at the practice
      super("Patient Login Started", null);
    }
  }

  export class PatientShortCodeOpened extends Metric {
    constructor(site_id: string, referrer: E_Referrer, comms_method: T_CommsMethod) {
      super("Patient Short Code Opened", site_id, { referrer, comms_method });
    }
  }

  export class PortalAccountSearched extends Metric {
    constructor(found: boolean) {
      //Site id is not known - they could be logging in to any site at the practice
      super("Portal Account Searched", null, { found });
    }
  }

  export class SessionExpired extends Metric {
    constructor(lastActivity: number) {
      super("Session Expired", null, { inactive_time_in_hours: ((Date.now() - lastActivity) / 3600000).toFixed(2) });
    }
  }

  export class TaskReminderSent extends Metric {
    constructor(site_id: string, hours_until_appointment: number) {
      super("Task Reminder Sent", site_id, { hours_until_appointment });
    }
  }

  export class TaskRemindersActivated extends Metric {
    constructor(via_promo: boolean) {
      const setProperties: Record<string, any> = {
        via_promo,
      };

      super("Task Reminders Activated", null, setProperties);
    }
  }

  export class TaskRemindersDisabled extends Metric {
    constructor() {
      super("Task Reminders Disabled", null);
    }
  }

  export class ActionDue_Payment extends Metric {
    constructor(referrer: I_Referrer, amount: number) {
      // site id is not known - they could be making a payment at any site at the practice
      super(OTHER_METRIC_NAMES.ACTION_DUE, null, { type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PAYMENT], referrer, amount });
    }
  }

  export class ActionOpened_Payment extends Metric {
    constructor(referrer: I_Referrer, amount: number) {
      // site id is not known - they could be making a payment at any site at the practice
      super(OTHER_METRIC_NAMES.ACTION_OPENED, null, { type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PAYMENT], referrer, amount });
    }
  }

  export class ActionDone_Payment extends Metric {
    constructor(siteId: string, referrer: I_Referrer, amount: number, payment_method: string | undefined) {
      const properties: PaymentMetricProperties = { type: ACTION_TYPE_METRIC_NAMES[E_Patient_Actions_Type.PAYMENT], referrer, amount };

      if (payment_method) properties.payment_method = payment_method;

      super(PERFORMANCE_DASHBOARD_METRIC_NAMES.ACTION_DONE, siteId, properties);
    }
  }

  export class NewPatientDetailsEntered extends Metric {
    constructor() {
      super("New Patient Details Entered", null);
    }
  }

  export class NewPatientVefified extends Metric {
    constructor() {
      super("New Patient Verified", null);
    }
  }

  export class ValidateRecaptcha extends Metric {
    constructor(valid: boolean, token_present: boolean, reasons: string, score?: number) {
      const setProperties: Record<string, any> = {
        valid,
        token_present,
        reasons,
      };

      if (score) setProperties.score = score; // score can be null or undefined

      super("Recaptcha Result", null, setProperties);
    }
  }

  export const PipMetrics = PipMetricsNS;

  // Manage metrics
  export class Manage_Login extends Metric {
    constructor(permission_level: number) {
      const setProperties: Record<string, any> = {};

      if (permission_level) setProperties.permission_level = permission_level;

      super("Manage Login", null, setProperties);
    }
  }

  export class Manage_StockingClicked extends Metric {
    constructor(count: number, position: "left" | "right") {
      const setProperties: Record<string, any> = {
        count,
        position,
      };

      super("Manage Stocking Clicked", null, setProperties);
    }
  }

  export class Manage_SantaShown extends Metric {
    constructor() {
      super("Manage Santa Shown", null);
    }
  }
}
