<p class="mb-4">
  Henry Schein One Canada (collectively, "our," "us," or "we") offers an online portal (the “Portal”) to patients of dental care providers, which includes both
  dental practices and referral centres, where applicable (“Dental Care Provider”). The Portal is a platform supplied to your Dental Care Provider who has
  contracted with us to provide the Portal on its behalf.
</p>
<p class="mb-4">
  This privacy policy applies to all end users of the Portal and covers what personal information we collect, use and transfer and why (“Privacy Policy”).
  Personal information is any information about an identifiable individual.
</p>
<p class="mb-4">
  By using the Portal and agreeing to the <a class="link" (click)="openTerms()"> Portal Terms</a>, you expressly consent to our collection, use, transfer, and
  retention of your personal information as described in this Privacy Policy. If you do not agree to this Privacy Policy and the Portal Terms, do not use the
  Portal.
</p>
<p class="mb-4">
  <strong class="block">1. Personal Health Information</strong>
</p>
<p class="mb-4">
  The Portal collects, uses and transfers your personal health information that you submit to your Dental Care Provider or that your Dental Care Provider
  provides to us. Our collection, use, and transfer of your personal health information is governed by applicable health privacy legislation, this Privacy
  Policy, and the privacy policy of your Dental Care Provider.
</p>
<p class="mb-4">1.1 <span class="underline">Use and Transfer of Your Personal Health Information</span></p>
<p class="mb-4">
  When you use the Portal, the personal health information that you submit is handled by us as a service provider, affiliate or agent, as defined by applicable
  health privacy legislation, according to the terms of the agreement between us and your Dental Care Provider. Please contact your Dental Care Provider for
  additional information on the processing of your personal health information.
</p>
<p class="mb-4">1.2 <span class="underline">How to Access, Change or Remove Your Personal Health Information</span></p>
<p class="mb-4">
  Your rights with respect to your personal health information will depend on the applicable legislation. These rights generally include the right to restrict
  the uses and disclosures of your personal health information, the right to access and receive a copy of your personal health information, the right to amend
  your personal health information, and the right to receive information about the disclosures of your personal health information. If you wish to exercise any
  of these rights, please contact your Dental Care Provider.
</p>
<p class="mb-4">
  Generally, our agreements with Dental Care Providers require us and our subcontractors to either return or destroy personal health information received upon
  the termination of the agreement. Accordingly, if the agreement between us and your Dental Care Provider has been terminated, then any personal health
  information that you submitted to the Portal, or otherwise maintained by us or a subcontractor in connection with the Portal will be returned to your Dental
  Care Provider or destroyed by us or such subcontractor.
</p>
<p class="mb-4">
  <strong class="block">2. Personal Information We Collect From You and / or Your Dental Care Provider</strong>
</p>
<p class="mb-4">We may collect the following personal information from you on behalf of your Dental Care Provider:</p>
<ul class="mb-4">
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">a.</span>
    <span><strong>Account or Registration Information:</strong> including your name, address, email address, telephone number, birthday, and password;</span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">b.</span>
    <span
      ><strong>Payment Information:</strong> where needed to process payments, including your credit or debit card number, expiration date, and card
      verification number;</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">c.</span>
    <span
      ><strong>Medical Information:</strong> where needed for the functionality of Portal services, including your medical history, present symptoms,
      treatments, insurance carrier and plan, and any other medical information provided through the Portal;</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">d.</span>
    <span
      ><strong>Appointment Information:</strong> where needed to schedule an appointment with your Dental Care Provider through our appointment services;
      and</span
    >
  </li>
  <li class="flex">
    <span class="mr-2 font-bold">e.</span>
    <span><strong>Your Submissions:</strong> which generally includes information you voluntarily provide through text boxes and/or import.</span>
  </li>
</ul>
<p class="mb-4">We collect the following personal information from your Dental Care Provider to enable your use of the Portal:</p>
<ul class="mb-4">
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">a.</span>
    <span
      ><strong>Verification Information:</strong> to confirm your identity and status as a patient of a Dental Care Provider, including your Account or
      Registration Information; and</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2 font-bold">b.</span>
    <span
      ><strong>Data Accessible by the Portal:</strong> including Payment Information, Medical Information and Appointment Information which can be viewed in the
      Portal and/or which can be used to fill out forms available on the Portal.</span
    >
  </li>
</ul>
<p class="mb-4">
  <strong class="block">3. Information We Collect From Other Technologies</strong>
</p>
<p class="mb-4">
  We may collect certain technical information when you use the Portal. For example, our servers receive and automatically collect information about your
  computer and browser, including, for instance, your IP address, browser type, domain name from which you accessed the Portal, device size and other software
  or hardware information. If you access the Portal from a mobile or other device, we may collect a unique device identifier assigned to that device (UDID),
  type of device, general GPS location, or other transactional information for that device in order to serve content to it and to improve your experience in
  using the Portal.
</p>
<p class="mb-4">
  In addition, we may collect information about how you use the Portal, including but not limited to, the date and time you visit the Portal, the areas of the
  Portal you access, the amount of time you spend viewing the Portal, the number of times you return to the Portal, visits outside our network, preferred
  language, and other click-stream data.
</p>
<p class="mb-4">3.1 <span class="underline">Cookies</span></p>
<p class="mb-4">
  We may use cookies to help us improve your experience, increase security, and measure use and effectiveness of our services. You can control the use of
  cookies on the Portal through our management tool, which will be available through a banner or pop-up that presents itself when you visit the Portal.
</p>
<p class="mb-4">
  <strong class="block">4. How We Use Your Personal Information</strong>
</p>
<p class="mb-4">4.1 <span class="underline">Personal Information We Collect From You and / or Your Dental Care Provider</span></p>
<p class="mb-4">We may use the personal information that we collect from you and / or your Dental Care Provider in one or more of the following ways:</p>
<ul class="mb-4">
  <li class="mb-4 flex">
    <span class="mr-2">a.</span>
    <span
      >to administer and manage the Portal, including to verify your identity and eligibility to access the Portal and to enable features of the Portal which
      allow you to view your information, submit information to your Dental Care Provider, schedule appointments with your Dental Care Provider, and complete
      forms;</span
    >
  </li>
  <li class="mb-4 flex">
    <span class="mr-2">b.</span>
    <span>to process payments on behalf of your Dental Care Provider;</span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2">c.</span>
    <span>to provide technical support; and</span>
  </li>
  <li class="flex">
    <span class="mr-2">d.</span>
    <span>to prevent fraud or potentially illegal activities, enforce the Portal Terms, or to comply with applicable law.</span>
  </li>
</ul>
<p class="mb-4">4.2 <span class="underline">Other Information We Automatically Collect Through Cookies and Other Technologies</span></p>
<p class="mb-4">We may use information collected from you through cookies and other tracking technologies in one or more of the following ways:</p>
<ul class="mb-4">
  <li class="mb-4 flex">
    <span class="mr-2">a.</span>
    <span>to remember you when you return to the Portal;</span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2">b.</span>
    <span>to understand and analyze trends, to monitor usage, and learn about user behavior;</span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2">c.</span>
    <span>to customize content on our Portal; and</span>
  </li>
  <li class="flex">
    <span class="mr-2">d.</span>
    <span>to improve our Portal and to make our Portal more useful for users.</span>
  </li>
</ul>
<p class="mb-4">
  <strong class="block">5. Sharing Personal Information</strong>
</p>
<p class="mb-4">
  We collect your personal information on behalf of your Dental Care Provider, and share the personal information you provide on the Portal with your Dental
  Care Provider, which hosts your personal information in Montreal, Quebec. Please refer to your Dental Care Provider’s terms and conditions and privacy policy
  for details as to how the Dental Care Provider handles your personal information.
</p>
<p class="mb-4">Apart from your Dental Care Provider, we share your personal information with third parties in the following circumstances:</p>
<ul class="mb-4">
  <li class="mb-4 flex">
    <span class="mr-2">a.</span>
    <span>
      when we engage third parties to perform services on our behalf. We share your personal information with the following types of service providers:
      <table class="mb-4 border-collapse border border-gray-300">
        <tr>
          <th class="border border-gray-300 px-4 py-2">Service Provider</th>
          <th class="border border-gray-300 px-4 py-2">Location</th>
          <th class="border border-gray-300 px-4 py-2">Purpose</th>
        </tr>
        <tr>
          <td class="border border-gray-300 px-4 py-2">Amazon Web Services AWS</td>
          <td class="border border-gray-300 px-4 py-2">Quebec, Canada</td>
          <td class="border border-gray-300 px-4 py-2">Application and Data Hosting Platform</td>
        </tr>
        <tr>
          <td class="border border-gray-300 px-4 py-2">Stripe</td>
          <td class="border border-gray-300 px-4 py-2">Canada</td>
          <td class="border border-gray-300 px-4 py-2">Payment Processing</td>
        </tr>
      </table>
    </span>
  </li>
  <li class="mb-4 flex">
    <span class="mr-2">b.</span>
    <span>
      to satisfy any applicable laws or regulations, including to defend ourselves in litigation or a regulatory action, in order to protect our rights or
      property, and to enforce our Portal Terms; and
    </span>
  </li>
  <li class="flex">
    <span class="mr-2">c.</span>
    <span>
      in the event of a merger, acquisition, debt financing, restructure, sale of our assets by or with another company, or a similar corporate transaction, we
      may need to disclose and transfer all information about you, including personal information, to the successor company.
    </span>
  </li>
</ul>
<p class="mb-4">
  <strong class="block">6. Retention</strong>
</p>
<p class="mb-4">
  We retain your personal information while your account is active or as needed to provide you services. Additionally, we may retain personal information even
  after your account has been closed if retention is reasonably necessary to comply with our legal or regulatory obligations, resolve disputes, prevent fraud
  and abuse, enforce our Portal Terms or any other contract entered into between you and us, or if it is not technically feasible to delete your personal
  information.
</p>
<p>
  <strong class="block">7. Email and Other Communications</strong>
</p>
<p class="mb-4">
  Where you have provided your phone number and consented to receive such communications, we send text communications via SMS in order to provide you with a two
  factor authentication code to use to log into the Portal or when registering a Portal account.
</p>
<p class="mb-4">
  Where you have provided your email address and consented to receive such communications, we send email communications in order to allow you to verify your
  email account and / or to allow you to reset your password to your Portal account.
</p>
<p class="mb-4">
  <strong class="block">8. Protecting Personal Information</strong>
</p>
<p class="mb-4">
  We use reasonable and appropriate administrative, physical, technical, and data security procedures and controls to safeguard your personal information.
</p>
<p class="mb-4">
  We use third-party service providers to manage credit card and payment processing. These service providers are not permitted to store, retain, or use billing
  Information except for the sole purpose of credit card and payment processing on our behalf. When you enter payment information to be processed by our third
  party service providers, we encrypt the transmission of that information using transport layer security (TLS) technology and do not store it on our systems.
</p>
<p class="mb-4">
  It is important to remember, however, that no system can guarantee 100% security at all times. Accordingly, we cannot guarantee the security of information
  stored on or transmitted to or from our services.
</p>
<p class="mb-4">
  <strong class="block">9. Linked Websites and Services</strong>
</p>
<p class="mb-4">
  The Portal may link to other websites and resources provided by your Dental Care Provider. Where this is the case, your Dental Care Provider is solely
  responsible for any websites associated with the Portal. We do not exercise control over these third party sites or services. Once you leave the Portal via a
  link, you should check the applicable privacy policy of the third-party's site or service.
</p>
<p class="mb-4">
  <strong class="block">10. Contacting Us</strong>
</p>
<p class="mb-4">
  If you have any questions or concerns about this privacy policy or our practices, please contact our privacy office at
  <a class="link" href="mailto:privacy@henryscheinone.com">privacy&#64;henryscheinone.com</a>.
</p>
<p class="mb-4">
  <strong class="block">11. Changes to this Privacy Policy</strong>
</p>
<p class="mb-4">
  We may revise this privacy policy from time to time and, if we do, we will update it on this page and modify the "Last Updated" date. Because our privacy
  policy can change at any time, we encourage you to reread it periodically to see if there have been any changes that affect you. Your use of the Portal
  following any such change constitutes your agreement that all information collected from or about you the Portal after the revised privacy policy is posted
  will be subject to the terms of the revised privacy policy.
</p>
<p class="mb-4">Last Updated: 11/02/2025</p>
