import { Injectable, NgZone } from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { Location } from "@angular/common";
import Bugsnag from "@bugsnag/js";
import { JWTService } from "./jwt.service";
import { LocationService } from "./location.service";

export type T_NavigationExtras = NavigationExtras & { originalDomain?: boolean };

@Injectable({ providedIn: "root" })
export class NavigationService {
  constructor(
    private _router: Router,
    private _location: Location,
    public zone: NgZone,
    private _jwtService: JWTService,
    private _locationService: LocationService
  ) {}

  public previous() {
    this._location.back();
  }
  public navigate(path: string, params?: T_NavigationExtras) {
    if (path.includes("my-dental") && this._jwtService.isRestricted) path = path.replace("my-dental", "restricted");

    let navLink = `/${path}`;
    navLink = navLink.replace(/\/\//, "/");

    Bugsnag.leaveBreadcrumb("Navigating", {
      path: navLink,
      params,
    });

    this.zone.run(() => {
      this._router.navigate([navLink], params).then(
        () => {},
        () => {}
      );
    });
  }

  public navigateToBooking(params?: NavigationExtras): void {
    const isPatient = this._jwtService.isPatient();
    const routePrefix = isPatient ? "my-dental" : "";
    const path = `${routePrefix}/book`;

    this.navigate(path, params);
  }

  public goToHome(): void {
    this._locationService.href = "/";
  }
}
