import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from "@angular/router";
import { JwtResolver } from "./shared/resolvers/jwt-resolver";
import { NavigationService } from "./shared/services/navigation.service";
import { JWTService } from "./shared/services/jwt.service";
import { BookableApptsService } from "./shared/services/bookable-appts.service";
import { LocalisationService } from "./shared/services/localisation.service";
import { Constants } from "src/constants";
import { loadComponentWithErrorHandling } from "./shared/utils/routing";

@Injectable()
export class PatientTypeSelectionAuthGuard {
  constructor(private _bookableApptsService: BookableApptsService, private _navigationService: NavigationService) {}
  canActivate(): boolean {
    if (this._bookableApptsService.shouldSkipNewExisting) {
      this._navigationService.navigate("/login");
      return false;
    }

    return true;
  }
}
@Injectable()
export class RestrictedAuthGuard {
  constructor(private _jwtService: JWTService, private _navigationService: NavigationService) {}
  canActivate(): boolean {
    if (this._jwtService.isRestricted) {
      this._navigationService.navigate("/restricted/access");
      return false;
    }

    return true;
  }
}

@Injectable()
export class LoginAuthGuard {
  constructor(private _jwtService: JWTService, private _navigationService: NavigationService, private _localisationService: LocalisationService) {}
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url === Constants.ROUTES.LOGIN_RE_ENTER_PASSWORD.path) {
      const isLoggedIn = this._jwtService.isLoggedIn();
      if (!isLoggedIn) this._navigationService.navigate("/error");
      return isLoggedIn;
    }

    if (this._jwtService.isLoggedIn()) {
      this._navigationService.navigate("/my-dental");
      return false;
    }

    return true;
  }
}

export const shortCodeRoutes: Routes = [
  {
    path: "r/:short_code",
    data: { appView: true },
    loadComponent: loadComponentWithErrorHandling(() => import("./shared/components/short-code/short-code.component").then((m) => m.ShortCodeComponent)),
  },
  {
    path: "re/:patient_id/:mobile_number",
    loadComponent: loadComponentWithErrorHandling(() =>
      import("./shared/components/recall-appointment-link-handler/recall-appointment-link-handler.component").then(
        (m) => m.RecallAppointmentLinkHandlerComponent
      )
    ),
  },
];
const bookableAppointmentRoutes: Routes = [
  {
    path: "book",
    loadChildren: () => import("./bookable-appointments/bookable-appointments.routing").then((m) => m.BOOKABLE_APPTS_ROUTES),
    resolve: {
      data: JwtResolver,
    },
    data: {
      appView: true,
    },
  },
];

export const miscRoutes: Routes = [
  // Used to preview the appointment(s) from manage app
  {
    path: "preview-appointment",
    loadComponent: loadComponentWithErrorHandling(() =>
      import("./bookable-appointments/site-appointment-preview/site-appointment-preview.component").then(
        (m) => m.BookableAppointmentSiteAppointmentPreviewComponent
      )
    ),
  },
];

const pairRoutes: Routes = [
  {
    path: "pair",
    loadChildren: () => import("./pair/pair.routing").then((m) => m.PAIR_ROUTES),
    data: {
      appView: true,
    },
  },
];
const preferencesRoutes: Routes = [
  {
    path: "preferences",
    loadChildren: () => import("./preferences/preferences.routing").then((m) => m.PREFERENCES_ROUTES),
    resolve: {
      data: JwtResolver,
    },
  },
];

const loginRoutesV4: Routes = [
  {
    path: "login",
    loadChildren: () => import("./login-v4/login-v4.routing").then((m) => m.LOGIN_ROUTES),
    canActivate: [LoginAuthGuard],
  },
];

const myDentalRoutes: Routes = [
  {
    path: "my-dental",
    loadChildren: () => import("./my-dental/my-dental.routing").then((m) => m.MY_DENTAL_ROUTES),
  },
  {
    path: "restricted",
    loadChildren: () => import("./restricted/restricted.routing").then((m) => m.RESTRICTED_ROUTES),
  },
];

const unsupportedRoute: Routes = [
  {
    path: "unsupported",
    loadComponent: loadComponentWithErrorHandling(() => import("./unsupported/unsupported/unsupported.component").then((m) => m.UnsupportedComponent)),
    resolve: {
      data: JwtResolver,
    },
  },
];

const noRoute: Routes = [
  {
    path: "",
    loadComponent: loadComponentWithErrorHandling(() =>
      import("./shared/components/patient-type-selection/patient-type-selection.component").then((m) => m.PatientTypeSelectionComponent)
    ),
    resolve: {
      data: JwtResolver,
    },
    canActivate: [PatientTypeSelectionAuthGuard],
  },
  {
    path: "**",
    redirectTo: "/",
  },
];

const signOutRoute: Routes = [
  {
    path: "signout",
    loadComponent: loadComponentWithErrorHandling(() => import("./shared/components/signout/signout.component").then((m) => m.SignoutComponent)),
    resolve: {
      data: JwtResolver,
    },
  },
];

const errorRoutes: Routes = [
  {
    path: "error",
    loadComponent: loadComponentWithErrorHandling(() => import("./default-no-route/default-no-route.component").then((m) => m.DefaultNoRouteComponent)),
  },
];

const brandSiteAliasRoute: Routes = [
  {
    path: "practices/:alias",
    loadComponent: loadComponentWithErrorHandling(() =>
      import("./shared/components/brand-site-alias/brand-site-alias.component").then((m) => m.BrandSiteAliasComponent)
    ),
    children: [
      {
        path: "**",
        loadComponent: loadComponentWithErrorHandling(() =>
          import("./shared/components/brand-site-alias/brand-site-alias.component").then((m) => m.BrandSiteAliasComponent)
        ),
      },
    ],
  },
];

const pipLoginRoutes: Routes = [
  {
    path: "pip-login",
    loadChildren: () => import("./pip-login/pip-login.routing").then((m) => m.PIP_ROUTES),
  },
  {
    path: "pip-login/thank-you",
    loadComponent: loadComponentWithErrorHandling(() =>
      import("./pip-login/pip-login-thank-you/pip-login-thank-you.component").then((m) => m.PipLoginThankYouComponent)
    ),
    data: {
      appView: true,
    },
  },
  {
    path: "pip-login/action/:type",
    loadComponent: loadComponentWithErrorHandling(() =>
      import("./pip-login/pip-login-shortcut/pip-login-shortcut.component").then((m) => m.PipLoginShortcutComponent)
    ),
    data: {
      appView: true,
    },
  },
];

export const PIP_ROUTES: Routes = [
  ...pipLoginRoutes,
  ...pairRoutes,
  ...errorRoutes,
  ...unsupportedRoute,
  ...myDentalRoutes,
  {
    path: "**",
    redirectTo: "/pair",
  },
];

export const APP_ROUTES: Routes = [
  ...miscRoutes,
  ...brandSiteAliasRoute,
  ...bookableAppointmentRoutes,
  ...loginRoutesV4,
  ...myDentalRoutes,
  ...errorRoutes,
  ...shortCodeRoutes,
  ...signOutRoute,
  ...preferencesRoutes,
  ...unsupportedRoute,
  ...noRoute,
].map((route) => {
  if (!route.redirectTo && route.path !== "restricted" && !route.path?.startsWith("r/") && !route.path?.startsWith("re/")) {
    route.canActivate = route.canActivate || [];
    route.canActivate.push(RestrictedAuthGuard);
  }

  return route;
});
