import { Component, OnInit, OnDestroy } from "@angular/core";
import dayjs from "dayjs";

@Component({
  selector: "dentr-clock",
  templateUrl: "./clock.component.html",
  standalone: true,
})
export class ClockComponent implements OnInit, OnDestroy {
  currentTime: string;
  private _interval: any;

  ngOnInit(): void {
    this._updateClock();
    this._interval = setInterval(() => this._updateClock(), 1000);
  }

  ngOnDestroy(): void {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

  private _updateClock(): void {
    this.currentTime = `${dayjs().format("HH:mm:ss")}`;
  }
}
