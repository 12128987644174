import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";

@Pipe({ name: "practiceCurrency", standalone: true })
export class PracticeCurrencyPipe implements PipeTransform {
  constructor(private _currencyPipe: CurrencyPipe) {}

  transform(data: { currency: string; value: number | string | null | undefined; digitsInfo?: string }): string | null {
    if (!data || !data.currency || data.value == null) return null;
    return this._currencyPipe.transform(data.value, data.currency, "symbol-narrow", data.digitsInfo || "1.2-2");
  }
}
