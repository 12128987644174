import { environment } from "src/environments/environment";
import { notifyAndLeaveBreadcrumb } from "./shared/utils/logging";

let AUTO_HIDE_TIMEOUT: NodeJS.Timeout | undefined;

function getAppLoadingSpinnerElement(): HTMLElement | null {
  return document.getElementById("app_loader_spinner");
}

export function isAppLoadingSpinnerVisible(): boolean {
  return getAppLoadingSpinnerElement() !== null;
}

export function hideAppLoadingSpinner(): void {
  if (AUTO_HIDE_TIMEOUT) {
    clearTimeout(AUTO_HIDE_TIMEOUT);
  }

  const appLoaderSpinner = getAppLoadingSpinnerElement();
  if (appLoaderSpinner) appLoaderSpinner.outerHTML = "";
}

/**
 * Checks the app loading spinner has been removed by the app initialisation flow
 *
 * @param timeout Timeout in seconds
 */
export function checkAppLoadingSpinnerIsRemoved(timeout: number): void {
  if (AUTO_HIDE_TIMEOUT) {
    clearTimeout(AUTO_HIDE_TIMEOUT);
  }

  AUTO_HIDE_TIMEOUT = setTimeout(() => {
    if (!isAppLoadingSpinnerVisible()) {
      return;
    }

    notifyAndLeaveBreadcrumb("app loading spinner still visible", { timeout });
  }, timeout * 1000);
}

export function isRootDomain(stage?: string, hostname = window.location.hostname): boolean {
  const rootDomains: Array<string> = [environment.HOST_ZONE];

  if (stage) {
    rootDomains.push(`${stage}.${environment.HOST_ZONE}`);
  }

  return rootDomains.includes(hostname);
}
