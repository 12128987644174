<div data-e2e-loading>
  <div class="animate-pulse">
    <ng-content></ng-content>
  </div>

  <ng-container *ngIf="repeat">
    <div *ngFor="let item of arr" class="animate-pulse">
      <ng-template [ngTemplateOutlet]="templateRef" [ngTemplateOutletContext]="{ $implicit: item }"></ng-template>
    </div>
  </ng-container>
</div>
