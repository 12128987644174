import { SHARED } from "src/app/shared/shared";
import { Component, ContentChild, Input, OnInit, TemplateRef } from "@angular/core";

@Component({
  selector: "dentr-skeleton-loader",
  templateUrl: "./skeleton-loader.component.html",
  standalone: true,
  imports: [SHARED],
})
export class SkeletonLoaderComponent implements OnInit {
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() repeat?: number;
  public arr = new Array<number>();

  public ngOnInit(): void {
    if (this.repeat) this.arr = Array.from({ length: this.repeat }, (_value, i) => i);
  }
}
