import { HttpClient } from "@angular/common/http";
import { Injectable, RendererFactory2 } from "@angular/core";
import { Subject, Subscription, filter, firstValueFrom, lastValueFrom, of, switchMap, timer } from "rxjs";
import { environment } from "src/environments/environment";
import { JWTService, isPublicInPractice } from "./jwt.service";
import { PushUpdatesService } from "./push-updates.service";
import { E_MessageKind } from "@backend/graph/_services/e-message-kind";
import { finaliseSecureCrossDomainDataSharing } from "../utils/secure-cross-domain-data-sharing";
import { CommonService } from "./common.service";
import { NavigationService } from "./navigation.service";
import { CacheService } from "./cache.service";
import { WindowService } from "./window.service";
import { SubSink } from "subsink";
import { HttpService } from "./http.service";
import { debugAndLeaveBreadcrumb, notifyAndLeaveBreadcrumb, setupBugsnag } from "../utils/logging";
import { LocationService } from "./location.service";
import { BrandService } from "./brand.service";
import { ReferrerService } from "./referrer.service";
import { E_Referrer } from "@backend/common/enums/referrer";
import Bugsnag from "@bugsnag/js";
import { PatientsService } from "./patients.service";
import { E_PipServiceMode } from "@shared/constants";
import { I_HeartbeatResponse } from "@backend/graph/devices/devices-base";
import { Constants } from "src/constants";
import { SessionService } from "./session.service";
import { setThemeColor, pipThemeColor } from "../utils/theme";
import { I_PipData, PortalInPracticeDataService } from "./portal-in-practice-data.service";
import { NotificationInstance, NotificationService, NotificationTypes } from "./notification.service";

const { HOST_ZONE } = environment;
const DEVICES_PATH = "/api/v1/devices";

export type PipDiagnosticsData = {
  id: string;
  practice_id: string;
  site_id: string;
  jwt_created_at: string;
  jwt_expiry: string;
};
@Injectable({
  providedIn: "root",
})
export class PortalInPracticeService {
  private _activationCode: string;
  private _practiceName: string;
  private _siteId: string;
  private _siteName: string;
  private _siteUrl: string;
  private _subs = new SubSink();
  private _deviceLocked = false;
  private _diagnosticsData: PipDiagnosticsData | null = null;
  private _heartbeatSubscription: Subscription;
  public onPaired: Subject<I_PipData> = new Subject<I_PipData>();
  public hadPairingError = false;

  constructor(
    private _http: HttpClient,
    private _jwtService: JWTService,
    private _pushUpdatesService: PushUpdatesService,
    private _rendererFactory: RendererFactory2,
    private _commonService: CommonService,
    private _navigationService: NavigationService,
    private _cacheService: CacheService,
    private _windowService: WindowService,
    private _httpService: HttpService,
    private _locationService: LocationService,
    private _brandService: BrandService,
    private _referrerService: ReferrerService,
    private _patientsService: PatientsService,
    private _sessionService: SessionService,
    private _portalInPracticeDataService: PortalInPracticeDataService,
    private _notificationService: NotificationService
  ) {
    this._subs.sink = this._jwtService.onJWTChanged
      .pipe(filter(({ status, jwt }) => status === "UPDATED" && isPublicInPractice(jwt.access_level)))
      .subscribe(() => {
        this._pushUpdatesService.reconnect();
      });
  }

  public get activationCode(): string {
    return this._activationCode;
  }

  public get practiceName(): string {
    return this._practiceName;
  }

  public get siteId(): string {
    return this._siteId;
  }

  public get siteName(): string {
    return this._siteName;
  }

  public get siteUrl(): string {
    return this._siteUrl;
  }

  private get _backendVersion(): string {
    return this._cacheService.get(Constants.BACKEND_VERSION_STORAGE_KEY) || environment.VERSION;
  }

  private set _backendVersion(backendVersion: string) {
    this._cacheService.set(Constants.BACKEND_VERSION_STORAGE_KEY, backendVersion);
  }

  public get isNewVersionAvailable(): boolean {
    return this._backendVersion !== environment.VERSION;
  }

  public onPageUnload(): void {
    this._subs.unsubscribe();
  }

  public get deviceId(): string | null {
    return this._jwtService.getJwtField("device_id");
  }

  public get practiceId(): string | null {
    return this._jwtService.getJwtField("practice_id");
  }

  public get isPairDomain(): boolean {
    return this._locationService.isPairDomain;
  }

  /**
   * Generate a public JWT so that the Pusher connection can be authenticated
   */
  public async initForPairing(): Promise<void> {
    const response = (await lastValueFrom(
      this._http.post(DEVICES_PATH, {
        withCredentials: true,
      })
    )) as { token: string; activationCode: string };
    const { token, activationCode } = response;

    this._jwtService.setPublicToken(token, true);
    this._activationCode = activationCode;
    this._connectToPusher();
    setThemeColor(pipThemeColor);
  }

  public async initForPaired(): Promise<void> {
    await lastValueFrom(
      this._http.get(DEVICES_PATH, {
        withCredentials: true,
      })
    );
    this._referrerService.referrer = E_Referrer.PORTAL_IN_PRACTICE;
    this._connectToPusher();
    this._setupHeartbeat();
  }

  private _onUnlink(): void {
    Bugsnag.notify("Device unlinked");
    this._portalInPracticeDataService.deletePipData();
    this._cacheService.delete(Constants.RESTRICTED_SITE_STORAGE_KEY);
    this._cacheService.delete(Constants.BRAND_INFO_STORAGE_KEY);
  }

  private _connectToPusher(): void {
    Bugsnag.leaveBreadcrumb("connecting to Pusher");

    this._subs.sink = this._pushUpdatesService.onConnected
      .pipe(
        switchMap(() => {
          const device_id = this._jwtService.getJWT("device_id");
          if (typeof device_id !== "string") {
            notifyAndLeaveBreadcrumb("Invalid device_id", { jwt: this._jwtService.getJwtSafe() });
            return of({});
          }
          return this._pushUpdatesService
            .createPrivateChannelObservable(device_id)
            .pipe(
              filter((data) =>
                [
                  E_MessageKind.Device_Linked,
                  E_MessageKind.Device_Unlinked,
                  E_MessageKind.Device_Locked,
                  E_MessageKind.Device_Unlocked,
                  E_MessageKind.Device_Pinged,
                ].includes(data.message_kind)
              )
            );
        })
      )
      .subscribe(async (data) => {
        switch (data.message_kind) {
          case E_MessageKind.Device_Linked:
            const { jwt } = data.message_content as I_PipData;

            this._jwtService.setToken(jwt);
            this._processPipData(data.message_content);

            this._portalInPracticeDataService.setPipData(data.message_content);

            break;

          case E_MessageKind.Device_Unlinked:
            this._onUnlink();
            await this._sessionService.clear();
            this._windowService.open(this.pairDomain);

            break;

          case E_MessageKind.Device_Locked:
            this._navigationService.navigate("/pip-login/locked");

            break;

          case E_MessageKind.Device_Pinged:
            const { username } = data.message_content;
            const siteNameString = this.siteName ? ` at ${this.siteName}` : "";
            this._notificationService.open(
              new NotificationInstance({
                title: "Connection test",
                body: `Performed by ${username}${siteNameString}`,
                type: NotificationTypes.INFO,
                timeout: 0, // Persistent notification
              })
            );
            break;

          case E_MessageKind.Device_Unlocked:
            this.deviceLocked = false;
            this._navigationService.navigate("/pip-login");

            break;
        }
      });
  }

  public async restorePipData(): Promise<boolean> {
    const data = this._portalInPracticeDataService.getPipData();

    if (data) {
      this._processPipData(data);

      // Make sure we get the patient before restoring the PiP data to ensure the brand is set correctly
      if (this._jwtService.isPatient()) {
        Bugsnag.leaveBreadcrumb("get patient");

        // The patient must of refreshed the page while completing their forms so we need to load their data again
        // to prevent in infinite loop caused by the call to reload() in AppComponent when there is a patient JWT
        // but no patient info
        await this._patientsService.getPatient();
      }

      await this._loadBrandData();

      return true;
    }

    return false;
  }

  public async logoutPatientInPractice(): Promise<void> {
    await this._sessionService.clear();
    this._jwtService.restorePublicInPracticeToken();
  }

  private _processPipData(data: I_PipData): void {
    const { practiceName, siteId, siteName, siteUrl, stage } = data;
    const url = new URL(siteUrl);

    if (!practiceName || !siteId || !siteName || !siteUrl || !stage) {
      Bugsnag.leaveBreadcrumb("Invalid PIP data", { data });
      Bugsnag.notify(new Error("Invalid PIP data"));
    }

    this._locationService.hostname = url.hostname;

    window.STAGE = stage;
    this._practiceName = practiceName;
    this._siteId = siteId;
    this._siteName = siteName;
    this._siteUrl = siteUrl;

    setupBugsnag(this.deviceId);

    this.onPaired.next(data);
  }

  public get pairDomain(): string {
    return `https://pair.${HOST_ZONE}`;
  }

  public get serviceMode(): E_PipServiceMode {
    return E_PipServiceMode.CONCIERGE;
  }

  public async finalisePairing(): Promise<void> {
    const renderer = this._rendererFactory.createRenderer(null, null);
    let url = this.pairDomain;

    if (window.localStorage.getItem("e2e")) {
      // we use the stage specific url for e2e tests
      url = document.referrer;
    } else {
      //document.referrer should always match url (except for e2es).
      //If it doesn't, it doesn't actually matter now because we are hardcoding the url, but is interesting as it might explain why the pairing sometimes failed.
      if (document.referrer !== url) console.error("document referrer does not match url", { referrer: document.referrer, url });
    }

    debugAndLeaveBreadcrumb("finalisePairing", { referrer: document.referrer, default_url: `${this.pairDomain}`, url });

    const data = await finaliseSecureCrossDomainDataSharing<{ jwt: string }>(renderer, "pip-pairing", url.replace(/\/$/, ""));
    if (!data) {
      this.hadPairingError = true;
      return;
    }

    const { jwt } = data;
    this._jwtService.setToken(jwt);
    this._commonService.getCommonData(true);
    this._commonService.onInitData.subscribe({
      next: () => {
        this._navigationService.navigate("/pip-login");
      },
      error: () => {
        this._navigationService.navigate("/error", { state: { error: "Failed to load common data" } });
      },
    });
    await this.initForPaired();
  }

  public set deviceLocked(value: boolean) {
    this._deviceLocked = value;
  }

  public get deviceLocked(): boolean {
    return this._deviceLocked;
  }

  public get diagnosticsData(): PipDiagnosticsData | null {
    return {
      id: this.deviceId || "unknown",
      practice_id: this.practiceId || "unknown",
      site_id: this.siteId || "unknown",
      // Given how often we create new JWTs for Concierge this will be accurate enough to represent the last heartbeat. It also means we will have the diagnostics data to help debug app loading issues
      jwt_created_at: this._jwtService.jwtCreatedAtFormatted || "unknown",
      jwt_expiry: this._jwtService.jwtExpiryFormatted || "unknown",
    };
  }

  public async continueToPipLogin(): Promise<void> {
    await this._loadBrandData();
    this._setupHeartbeat();

    this._navigationService.navigate("/pip-login");
  }

  private async _waitForCommonData() {
    const promise = firstValueFrom(this._commonService.onInitData);
    this._commonService.getCommonData(true);

    await promise;
  }

  private async _loadBrandData(): Promise<void> {
    Bugsnag.leaveBreadcrumb("getting domain info");

    await this._commonService.getDomainInfo();

    Bugsnag.leaveBreadcrumb("waiting for common data");

    await this._waitForCommonData();

    this._brandService.restrictedSiteId = this._siteId;
  }

  private _setupHeartbeat(): void {
    if (this._heartbeatSubscription?.closed === false) {
      this._heartbeatSubscription.unsubscribe();
    }

    Bugsnag.leaveBreadcrumb("setting up heartbeat");

    this._heartbeatSubscription = timer(0, 600000) // Immediately, then every 10 minutes
      .pipe(
        switchMap(() => {
          return this._httpService.mutation(
            "deviceHeartbeat",
            `{
              deviceHeartbeat(id: "${this._jwtService.getJWT("device_id")}", version: "${environment.VERSION}") {
                success
                locked
                version
              }
          }`
          );
        })
      )
      .subscribe((response: { data: { deviceHeartbeat: I_HeartbeatResponse } }) => {
        if (response.data.deviceHeartbeat.locked) {
          this.deviceLocked = true;
        }

        if (response.data.deviceHeartbeat.version) {
          this._backendVersion = response.data.deviceHeartbeat.version;
        }
      });

    this._subs.sink = this._heartbeatSubscription;
  }
}
