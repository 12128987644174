import { Component } from "@angular/core";
import { SHARED } from "src/app/shared/shared";
import { OverlayInstance, OverlayService } from "src/app/shared/services/overlay.service";
import { LegalComponent } from "../../../legal.component";

@Component({
  selector: "dentr-privacy-policy-ca",
  templateUrl: "./privacy-policy-ca.component.html",
  standalone: true,
  imports: [SHARED],
})
export class PrivacyPolicyCAComponent {
  constructor(private _overlayService: OverlayService) {}

  public openTerms(): void {
    const overlay: OverlayInstance = {
      component: LegalComponent,
      data: {
        is_modal: true,
      },
    };
    this._overlayService.open(overlay);
  }
}
