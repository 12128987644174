<footer>
  <div>
    <ul class="m-0 flex list-none flex-wrap justify-center p-0 text-xs">
      <li class="mb-4 px-4 text-center sm:text-start" *ngIf="!jwtService.isLoggedIn() && !jwtService.isRestricted">
        <span class="link" (click)="goToHelp()">Get help</span>
      </li>

      <li class="mb-4 px-4 text-center sm:text-start">
        <a class="link" (click)="openTerms()">Terms of use</a>
      </li>

      <li class="mb-4 px-4 text-center sm:text-start">
        <a class="link" *ngIf="privacy_policy_url" href="{{ privacy_policy_url }}" target="_blank">Privacy Policy</a>
        <span *ngIf="!privacy_policy_url" class="link" (click)="openPrivacyPolicy()">Privacy Policy</span>
      </li>
    </ul>
  </div>
</footer>
