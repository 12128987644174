<span *ngIf="is_modal; then thenBlock; else elseBlock"></span>
<ng-template #thenBlock>
  <dentr-modal>
    <div #modalScroll>
      <ng-container *ngTemplateOutlet="terms"></ng-container>
      <button class="btn-secondary w-full sm:w-auto" (click)="closeModal()">Close</button>
    </div>
  </dentr-modal>
</ng-template>
<ng-template #elseBlock>
  <ng-container *ngTemplateOutlet="terms"></ng-container>
</ng-template>
<ng-template #terms>
  <ng-container *ngComponentOutlet="selectedTermsComponent"></ng-container>
</ng-template>
