import { Injectable } from "@angular/core";
import { notifyAndLeaveBreadcrumb } from "../utils/logging";

@Injectable({
  providedIn: "root",
})
export class NewRelicService {
  public setUserId(userId: string): void {
    try {
      if (window.newrelic) {
        window.newrelic.setUserId(userId);
      }
    } catch (error) {
      notifyAndLeaveBreadcrumb("Failed to set user id in New Relic", { error });
    }
  }
}
