import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, filter, Subject } from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { GRAPHQL_OPERATION_NAMES, HttpService } from "./http.service";
import { validateNhsNumber } from "../utils/nhs-number-validator";
import { PatientBase } from "../../../../../../backend/src/graph/patients/patient-base";
import { PatientEntry } from "src/app/data_model/patient";
import { JWTService } from "./jwt.service";
import { Constants } from "src/constants";
import { CacheService } from "./cache.service";
import Bugsnag from "@bugsnag/js";
import * as palette from "../../../../palette";
import { catchError, map } from "rxjs/operators";
import { LambdaRequest } from "@apis/_core/types/LambdaRequest";
import { filterDefined } from "../utils/rxjs";
import { toUnquotedJSON } from "@shared/json-utils";
import { LocalisationService } from "./localisation.service";

export type T_Patient_Country_Fields = Pick<
  PatientBase,
  "emergency_contact_phone_country" | "gp_practice_phone_country" | "home_phone_country" | "mobile_phone_country"
>;

/* email_address, mobile_phone, mobile_phone_country are dynamic based on the communication method
the short code was sent via. For example, if the short code was sent via email, then the email_address
would be returned but mobile_phone and mobile_phone_country would be null. */
export const PATIENT_UNAUTHENTICATED_QUERY = (isPip) => `
  {
    patient {
      acquisition_source_id
      first_name
      id
      payment_plan_id
      portal_url_full
      prevent_appointment_booking
      registered_portal_user
      site_id
      preferred_phone_number
      age
      ${
        isPip
          ? `
          date_of_birth
          email_address
          last_name
          mobile_phone
          mobile_phone_country
          dentist {
            full_name
            id
          }`
          : ""
      }

      dentist_recall_date
      hygienist_recall_date
      is_nhs
      nhs_country
    }
  }`;
export const PATIENT_QUERY = `
  {
    patient {
      acquisition_source_id
      account_id
      active
      bad_debtor
      date_of_birth
      dentist {
        full_name
      }
      dentist_id
      dentist_recall_date
      dentist_recall_interval
      email_address
      emergency_contact_name
      emergency_contact_phone
      emergency_contact_phone_country
      emergency_contact_phone_normalized
      emergency_contact_relationship
      gp_practice_name
      gp_practice_address_line_1
      gp_practice_address_line_2
      gp_practice_address_line_3
      gp_practice_address_line_4
      gp_practice_address_line_5
      gp_practice_postcode
      gp_practice_phone
      ethnicity
      first_name
      gender
      hygienist {
        full_name
      }
      hygienist_id
      hygienist_recall_date
      hygienist_recall_interval
      id
      last_name
      marketing
      mobile_phone_country
      mobile_phone_normalized
      mobile_phone
      home_phone_country
      home_phone
      work_phone_country
      work_phone
      nhs_country
      nhs_number
      ni_number
      payment_plan_id
      preferred_phone_number
      prevent_appointment_booking
      registered_portal_user
      site_id
      use_email
      use_sms

      address_line_1
      address_line_2
      address_line_3
      town
      county
      postcode

      first_appointment_date
      first_exam_date
      last_appointment_date
      last_cancelled_appointment_date
      last_exam_date
      last_fta_appointment_date
      last_scale_and_polish_date
      next_appointment_date
      next_exam_date
      next_scale_and_polish_date
      nhs_exemption_code
      can_book_together_dental
      can_book_together_hygiene

      age
      cancellation_policy {
        can_cancel_online
        can_cancel_online_up_to_hours
        charge_if_less_than_hours
        charge_per_minute
      }
      is_nhs
      portal_url
      portal_url_full
      portal_url_is_old_world
    }
  }
`;

export enum E_PatientInfoStatus {
  SUCCESS = "SUCCESS",
  NO_CHANGE = "NO_CHANGE",
  SAVING = "SAVING",
  ERROR = "ERROR",
}

@Injectable({
  providedIn: "root",
})
export class PatientsService {
  private _onPatientInfo = new BehaviorSubject<PatientEntry | null>(null);
  public onPatientInfoUpdated = new Subject<E_PatientInfoStatus>();
  public patientInfo: PatientEntry | null = null;
  public requestsInProgress = {
    getPatient: false,
  };
  private _palette = new Array<string>();

  constructor(
    private _httpService: HttpService,
    private _jwtService: JWTService,
    private _cacheService: CacheService,
    private _localisationService: LocalisationService
  ) {
    if (!this._palette.length) this._palette = Object.keys(palette).slice(0, 6);

    this._jwtService.onJWTChanged.pipe(filter((jwt) => !LambdaRequest.patientAccessLevels.includes(jwt?.access_level))).subscribe(() => {
      this.patientInfo = null;
      this._onPatientInfo.next(null);
    });
  }

  public get isOver18(): boolean {
    return (this.patientInfo?.age ?? 0) >= 18;
  }

  public get onPatientInfo(): Observable<PatientEntry | null> {
    if (this._onPatientInfo) return this._onPatientInfo.pipe(filterDefined());
    return of(null);
  }

  public get isNHSScotland(): boolean {
    return this.patientInfo?.nhs_country === "SC";
  }

  /**
   * The Patient App Main Resolver
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, _reject) => {
      resolve(true);
    });
  }

  public defaultPropertyCountryCode(property: keyof T_Patient_Country_Fields): string {
    return this.patientInfo?.[property] || this._localisationService.country_code;
  }

  private _setPatientInfo(patient: PatientBase): void {
    this.patientInfo = new PatientEntry(Object.assign(this.patientInfo || {}, patient));

    // This value will be set if the patient has authenticated via a recall appointment link
    const recallApptDateOfBirth = this._cacheService.get(Constants.RECALL_APPT_LINK_DOB_STORAGE_KEY);
    if (recallApptDateOfBirth) {
      this.patientInfo.date_of_birth = recallApptDateOfBirth;
      this._cacheService.delete(Constants.RECALL_APPT_LINK_DOB_STORAGE_KEY);
    }

    const alphabetPosition = this.patientInfo.first_name.toLowerCase().charCodeAt(0) - 96;
    const colour = this._palette[alphabetPosition % this._palette.length];
    this.patientInfo.bg_class = `bg-${colour}-200`;
    this.patientInfo.text_class = `text-${colour}-700`;

    this._onPatientInfo.next(this.patientInfo);
  }

  private _getPatientQuery(): string {
    const is_patient_unauthenticated = this._jwtService.isPatientUnauthenticated();
    // We only set this value if the patient has authenticated via a recall appointment link (see recall-appointment-link-handler.component.ts)
    const is_recall_flow = this._cacheService.get(Constants.RECALL_APPT_LINK_DOB_STORAGE_KEY);
    if (is_recall_flow) return PATIENT_QUERY;
    return is_patient_unauthenticated ? PATIENT_UNAUTHENTICATED_QUERY(this._jwtService.isPip()) : PATIENT_QUERY;
  }

  public async getPatient(): Promise<void> {
    try {
      this._cacheService.deleteSession(Constants.RESTRICTED_SITE_STORAGE_KEY);
      const query = this._getPatientQuery();
      const response: any = await this._httpService.query<PatientBase>(GRAPHQL_OPERATION_NAMES.PATIENT, query).pipe().toPromise();

      if (response?.data?.patient) {
        this._setPatientInfo(response.data.patient);
      } else {
        Bugsnag.notify("Patient query returned no data");
      }
    } catch (error) {
      console.error("Error in getPatient", error);
      throw error;
    }
  }

  // Only get the changed fields with the existing patient info. This will prevent unchanged fields being exposed to unauthenticated patients
  private _getUpdatedDetails(patient: Partial<PatientEntry>): Array<string> {
    const changed_fields = new Array<string>();

    for (const field in patient) {
      // Treat undefined, empty string and null as the same
      if (!patient[field] && !this.patientInfo?.[field]) continue;
      if (patient[field] !== this.patientInfo?.[field]) {
        changed_fields.push(field);
      }
    }

    const has_emergency_contact = changed_fields.includes("emergency_contact_phone") || changed_fields.includes("emergency_contact_phone_country");
    if (has_emergency_contact) changed_fields.push("emergency_contact_phone_normalized");

    return changed_fields;
  }

  public updatePatientDetails(details: Partial<PatientEntry>, fromAction: boolean): void {
    if (details.nhs_number) {
      const isValid = validateNhsNumber(details.nhs_number);
      if (!isValid) throw new Error("Invalid NHS Number");
    }

    // For each field trim the whitespace
    for (const field in details) {
      if (typeof details[field] === "string") details[field] = details[field].trim();
    }

    const changed_fields = this._getUpdatedDetails(details);
    if (!changed_fields.length) {
      this.onPatientInfoUpdated.next(E_PatientInfoStatus.NO_CHANGE);
      return;
    }

    this.onPatientInfoUpdated.next(E_PatientInfoStatus.SAVING);
    this._httpService
      .mutation<PatientBase>(
        "updatePatientDetails",
        `{
          updatePatientDetails(details: ${toUnquotedJSON(details)} fromAction: ${fromAction}) {
            details {
              ${changed_fields.join("\n")}
            }
          }
      }`
      )
      .subscribe((response) => {
        if (response.errors) {
          Bugsnag.leaveBreadcrumb("Error updating patient details", {
            patientId: this.patientInfo?.id,
            errors: response.errors,
          });
          Bugsnag.notify(response.errors);
          this.onPatientInfoUpdated.next(E_PatientInfoStatus.ERROR);
          return;
        }
        if (response?.data?.updatePatientDetails?.details) {
          this._setPatientInfo(response.data.updatePatientDetails.details);
          this.onPatientInfoUpdated.next(E_PatientInfoStatus.SUCCESS);
        }
      });
  }

  public verifyPatientDetails(details: Partial<PatientBase>): Observable<{ success: boolean; rateLimitExceeded?: boolean }> {
    return this._httpService
      .mutation<PatientBase>(
        "verifyPatientDetails",
        `{
        verifyPatientDetails(patient_details: ${toUnquotedJSON(details)}) {
          rateLimitExceeded
          success
          email_address
          mobile_phone
          mobile_phone_country
        }
      }`
      )
      .pipe(
        map((response) => {
          const result = response?.data?.verifyPatientDetails || { success: false };

          if (!result.success) return result;

          if (result.email_address || result.mobile_phone) {
            this._setPatientInfo({
              email_address: result.email_address,
              mobile_phone: result.mobile_phone,
              mobile_phone_country: result.mobile_phone_country,
            } as PatientBase);
          } else {
            Bugsnag.notify("Patient contact details query returned no data");
          }

          return result;
        }),
        catchError((error) => {
          Bugsnag.notify(error);
          return of({ success: false });
        })
      );
  }
}
