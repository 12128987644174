import { Component, Input, OnDestroy, OnInit, Type } from "@angular/core";
import { CommonService } from "src/app/shared/services/common.service";
import { OverlayService } from "src/app/shared/services/overlay.service";
import { SubSink } from "subsink";
import { ModalComponent } from "../../modal/modal.component";
import { PrivacyPolicyDefaultComponent } from "./privacy-policies/privacy-policy-default/privacy-policy-default.component";
import { PrivacyPolicyCAComponent } from "./privacy-policies/privacy-policy-ca/privacy-policy-ca.component";
import { PrivacyPolicyAUComponent } from "./privacy-policies/privacy-policy-au/privacy-policy-au.component";
import { SHARED } from "src/app/shared/shared";

type PrivacyPolicyComponentType = Type<PrivacyPolicyDefaultComponent | PrivacyPolicyCAComponent | PrivacyPolicyAUComponent>;

@Component({
  selector: "dentr-privacy-policy",
  templateUrl: "./privacy-policy.component.html",
  standalone: true,
  imports: [ModalComponent, SHARED],
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  @Input()
  public is_modal = true;
  private _subs = new SubSink();
  public iso_country_code = "GB"; // GB is the default region

  private _privacyPolicyMap: Record<string, PrivacyPolicyComponentType> = {
    CA: PrivacyPolicyCAComponent,
    AU: PrivacyPolicyAUComponent,
    NZ: PrivacyPolicyAUComponent,
  };

  constructor(private _commonService: CommonService, private _overlayService: OverlayService) {
    this.iso_country_code = this._commonService.practice.iso_country_code;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  public closeModal(): void {
    this._overlayService.close();
  }

  get selectedPolicyComponent(): PrivacyPolicyComponentType {
    return this._privacyPolicyMap[this.iso_country_code] || PrivacyPolicyDefaultComponent;
  }
}
