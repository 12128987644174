import BugsnagPerformance from "@bugsnag/browser-performance";
import Bugsnag from "@bugsnag/js";
import { environment } from "../../../environments/environment";
import { EventsService } from "../services/events.service";

const BUGSNAG_API_KEY = "577179af892ddc33c658dd488c901ca7";

export function setupBugsnag(uniqueId?: string | null): void {
  if (Bugsnag.isStarted()) {
    return;
  }

  Bugsnag.start({
    apiKey: BUGSNAG_API_KEY,
    collectUserIp: false,
    appVersion: environment.VERSION,
    releaseStage: environment.STAGE ? environment.STAGE : "unset",
    onError: (event) => {
      console.warn("Bugsnag error", event);
    },
  });

  if (uniqueId) {
    Bugsnag.setUser(uniqueId);
  }

  BugsnagPerformance.start(BUGSNAG_API_KEY);

  new EventsService().emit("bugsnag:ready");
}

export function debugAndLeaveBreadcrumb(message: string, data: Record<string, any>): void {
  Bugsnag.leaveBreadcrumb(message, data);
  console.debug(message, data);
}

export function notifyAndLeaveBreadcrumb(message: string, data?: Record<string, any>): void {
  Bugsnag.leaveBreadcrumb(message, data);
  Bugsnag.notify(message);
}
