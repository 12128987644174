<div
  class="mb-4 bg-gray-200"
  [ngClass]="{
    'h-14': size === 'sm',
    'h-24': size === 'md',
    'h-48': size === 'lg',
    'rounded-full': rounded,
    'rounded-md': !rounded
  }"
  class="{{ height }}"
></div>
