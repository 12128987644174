import { SHARED } from "src/app/shared/shared";
import { Component, Input } from "@angular/core";

@Component({
  selector: "dentr-skeleton-default",
  templateUrl: "./skeleton-default.component.html",
  standalone: true,
  imports: [SHARED],
})
export class SkeletonDefaultComponent {
  @Input() public size?: "sm" | "md" | "lg" | "custom" = "md";
  @Input() public height?: string;
  @Input() rounded? = false;
  public heightString = "";
}
